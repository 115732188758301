import { ReactComponent as Google } from '../../assets/imgs/google.svg';
import { ReactComponent as Facebook } from '../../assets/imgs/facebook.svg';
import massageclub from '../../assets/imgs/massageclub.png';
import messages from '../../assets/imgs/messages.png';
import avatar from '../../assets/imgs/avatar.png';
import reviewsBanner from '../../assets/imgs/bgs/reviews.jpg';
import hearts from '../../assets/imgs/hearts.jpg';
import Banner from '../Banner';
import { ReactComponent as Logo } from '../../assets/imgs/logo.svg';

import styles from '../Reviews/reviews.module.css';
import reviews from '../Reviews/reviews.json';

const Reviews = () => {
  const columns = reviews.testimonial.items.sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  );

  return (
    <div className={styles.whiteBackground}>
      <Banner title={'Reviews'} src={reviewsBanner} hasOverlay isSmall />

      <div className={styles.gridContainerVertical}>
        {columns.map((review) => (
          <div
            className={styles.gridItemVertical}
            style={{
              backgroundImage: `url(${hearts})`
            }}
          >
            <div className={styles.logoContainer}>
              <Logo alt='GTA Home Spa' height={10} />
              GTA HOME SPA
            </div>

            <div
              key={review.personName + review.createdAt}
              className={styles.gridItem}
            >
              <div>
                <div className={styles.reviewHeader}>
                  <div className={styles.stars}></div>
                  {review.source === 'google' && <Google height={20} />}
                  {review.source === 'facebook' && <Facebook height={20} />}
                  {review.source === 'massageclub' && (
                    <img src={massageclub} alt='massage club' height={20} />
                  )}
                  {review.source === 'messages' && (
                    <img src={messages} alt='messages' height={20} />
                  )}
                </div>
                {review.commentText}
                <div className={styles.avatar}>
                  <img
                    src={review.personPicture ? review.personPicture : avatar}
                    alt=''
                  />{' '}
                  {review.personName}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
