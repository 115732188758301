import ConsentForm from '../ConsentForm';
import HealthHistoryForm from '../HealthHistoryForm';
import TreatmentForm from '../TreatmentForm';

const FormDetails = () => {
  return (
    <div>
      <ConsentForm />
      <HealthHistoryForm />
      <TreatmentForm view />
    </div>
  );
};

export default FormDetails;
