import { useEffect, useState } from 'react';
import axios from 'axios';

import settings from '../../config';
import Heading from '../Heading';
import { Link } from 'react-router-dom';

const Forms = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(`${settings.api}consents/`);

        if (response.data) {
          setData(response.data.data);
        }
      } catch (e) {
        console.error('Error fetching document: ', e);
      }
    })();
  }, []);

  let treatments = 0;

  return (
    <div>
      <Heading title='Client Forms' />
      <div>
        <div>
          <div>
            {data.map((form) => {
              treatments += +form.treatments;
              return (
                <div key={form.code}>
                  <Link to={`/form/details/${form.code}`}>
                    {form.name} ({form.treatments})
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div>Total number of treatments: {treatments}</div>
      </div>
    </div>
  );
};

export default Forms;
