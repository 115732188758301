import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/imgs/logo.svg';

import styles from './navBar.module.css';
import { useState } from 'react';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.nav}>
      <div className={`${styles.group} ${styles.left} ${styles.hidden}`}>
        <Link to='/about-us'>About Us</Link>
        <Link to='/services'>Services</Link>
      </div>

      <div className={`${styles.group} ${styles.left} ${styles.mobile}`}>
        <Link to='/booking'>Book Now</Link>
      </div>

      <Link to='/' title='GTA Home Spa'>
        <Logo alt='GTA Home Spa' height={10} className={styles.logo} />
      </Link>

      <div className={`${styles.group} ${styles.right} ${styles.hidden}`}>
        <Link to='/booking'>Book Now</Link>
        <Link to='/gift-card'>Give Love</Link>
      </div>

      <div className={`${styles.group} ${styles.mobile} ${styles.right}`}>
        <div
          className={styles.hamburger}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div />
          <div />
          <div />
        </div>
      </div>

      <div
        className={`${styles.mobile} ${styles.slider} ${
          (isOpen && styles.open) || ''
        }`}
      >
        <div
          className={styles.close}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          X
        </div>
        <Logo alt='GTA Home Spa' height={10} />
        <Link
          onClick={() => {
            setIsOpen(false);
          }}
          to='/'
        >
          GTA HOME SPA
        </Link>
        <Link
          onClick={() => {
            setIsOpen(false);
          }}
          to='/about-us'
        >
          About Us
        </Link>
        <Link
          onClick={() => {
            setIsOpen(false);
          }}
          to='/services'
        >
          Services
        </Link>
        <Link
          onClick={() => {
            setIsOpen(false);
          }}
          to='/gallery'
        >
          Gallery
        </Link>
        <Link
          onClick={() => {
            setIsOpen(false);
          }}
          to='/reviews'
        >
          Reviews
        </Link>
        <Link
          onClick={() => {
            setIsOpen(false);
          }}
          to='/booking'
        >
          Book Now
        </Link>
        <Link
          onClick={() => {
            setIsOpen(false);
          }}
          to='/gift-card'
        >
          Give Love
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
