import { useEffect, useRef, useState } from 'react';
import { secondsToTime } from '../../utils';

import styles from './stopWatch.module.css';

const StopWatch = ({ className, seconds, setCurrentTime }) => {
  const timerRef = useRef(null);
  const timeCallbackRef = useRef(null);
  const [localSeconds, setLocalSeconds] = useState(0);

  useEffect(() => {
    setLocalSeconds(0);
    timerRef.current = setInterval(() => timeCallbackRef.current(), 1000);

    return () => clearInterval(timerRef.current);
  }, [seconds]);

  timeCallbackRef.current = () => {
    setLocalSeconds(localSeconds + 1);
    setCurrentTime(localSeconds + 1);
  };

  const { h, m, s } = secondsToTime(localSeconds);

  return (
    <div className={styles.stopWatch}>{`${
      h.toString().length === 1 ? `0${h}` : h
    } : ${m.toString().length === 1 ? `0${m}` : m} : ${
      s.toString().length === 1 ? `0${s}` : s
    }`}</div>
  );
};

export default StopWatch;
