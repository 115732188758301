import Heading from '../Heading';
import Checkbox from '../Checkbox';

import Button from '../Button';

import { ReactComponent as Health } from '../../assets/imgs/health.svg';
import { ReactComponent as LifeStyle } from '../../assets/imgs/life-style.svg';

import styles from './booking.module.css';

const healthConditions = [
  'Arthritis',
  'Cancer',
  'Digestive Disorders',
  'Fibromyalgia',
  'Plantar Fasciitis',
  'Pregnancy',
  'Sciatica'
];

const lifeStyle = ['On my feet', 'Sedentary', 'Sports/Athletic'];

const Step3 = ({ bookingDetails, setBookingDetails, next, prev, GiftCard }) => {
  const selectedHealthConditions = bookingDetails.healthConditions || [];
  const selectedLifeStyle = bookingDetails.lifeStyle || [];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (bookingDetails.treatmentType === 'foot reflexology') {
          next(8);
        } else {
          next(5);
        }
      }}
    >
      <Heading title='Do any apply to you?' />
      {GiftCard}

      <div className={`${styles.subheading} ${styles.padding}`}>
        Understanding your needs allows therapist to provide a more comfortable
        experience
      </div>

      <div className={styles.padding}>
        <Health alt='' height={16} /> <span>HEALTH CONDITIONS</span>
        <div className={styles.grid}>
          {healthConditions.map((healthCondition) => (
            <div
              className={`${styles.gridItem} ${styles.zebra} ${
                selectedHealthConditions.indexOf(healthCondition) !== -1
                  ? styles.selected
                  : ''
              } `}
              key={healthCondition}
              onClick={() => {
                if (selectedHealthConditions.indexOf(healthCondition) === -1) {
                  setBookingDetails({
                    ...bookingDetails,
                    healthConditions: [
                      ...selectedHealthConditions,
                      healthCondition
                    ]
                  });
                } else {
                  setBookingDetails({
                    ...bookingDetails,
                    healthConditions: selectedHealthConditions.filter(
                      (s) => s !== healthCondition
                    )
                  });
                }
              }}
            >
              <Checkbox
                isSelected={
                  selectedHealthConditions.indexOf(healthCondition) !== -1
                }
              />{' '}
              <div>{healthCondition}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.padding}>
        <LifeStyle alt='' height={16} /> <span>LIFE STYLE</span>
        <div className={styles.grid}>
          {lifeStyle.map((ls) => (
            <div
              className={`${styles.gridItem} ${styles.zebra} ${
                selectedLifeStyle.indexOf(ls) !== -1 ? styles.selected : ''
              } `}
              key={ls}
              onClick={() => {
                if (selectedLifeStyle.indexOf(ls) === -1) {
                  setBookingDetails({
                    ...bookingDetails,
                    lifeStyle: [...selectedLifeStyle, ls]
                  });
                } else {
                  setBookingDetails({
                    ...bookingDetails,
                    lifeStyle: selectedLifeStyle.filter((s) => s !== ls)
                  });
                }
              }}
            >
              <Checkbox isSelected={selectedLifeStyle.indexOf(ls) !== -1} />{' '}
              <div>{ls}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button type='button' text='Back' small action={() => prev(3)} />
        <Button type='submit' text='Continue' />
      </div>
    </form>
  );
};

export default Step3;
