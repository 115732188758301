import { ReactComponent as Google } from '../../assets/imgs/google.svg';
import { ReactComponent as Facebook } from '../../assets/imgs/facebook.svg';
import massageclub from '../../assets/imgs/massageclub.png';
import messages from '../../assets/imgs/messages.png';
import avatar from '../../assets/imgs/avatar.png';
import reviewsBanner from '../../assets/imgs/bgs/reviews.jpg';
import Banner from '../Banner';

import styles from './reviews.module.css';
import reviews from './reviews.json';

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}

const Reviews = () => {
  const columns = reviews.testimonial.items.sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  );

  return (
    <div>
      <Banner title={'Reviews'} src={reviewsBanner} hasOverlay isSmall />

      <div className={styles.gridContainer}>
        {columns.map((review) => (
          <div
            key={review.personName + review.createdAt}
            className={styles.gridItem}
          >
            <div>
              <div className={styles.reviewHeader}>
                <div className={styles.stars}></div>
                <span>
                  {timeSince(new Date(new Date(review.createdAt)))} ago
                </span>
                {review.source === 'google' && <Google height={20} />}
                {review.source === 'facebook' && <Facebook height={20} />}
                {review.source === 'massageclub' && (
                  <img src={massageclub} alt='massage club' height={20} />
                )}
                {review.source === 'messages' && (
                  <img src={messages} alt='messages' height={20} />
                )}
              </div>
              {review.commentText}
              <div className={styles.avatar}>
                <img
                  src={review.personPicture ? review.personPicture : avatar}
                  alt=''
                />{' '}
                {review.personName}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
