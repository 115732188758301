import React, { useState, useEffect } from 'react';
import ReactCalendar from 'react-calendar';
import axios from 'axios';

import settings from '../../config';
import TimelineSlots from '../TimelineSlots';

import styles from './timeLine.module.css';

const Timeline = () => {
  const [value, onChange] = useState(new Date());
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const getBookings = async () => {
      const response = await axios.post(`${settings.api}bookings/by-date`, {
        date: new Date(value).toISOString().slice(0, 10)
      });

      if (response.data.statusCode === 200) {
        setBookings(response.data.data);
      }
    };
    getBookings();
  }, [value]);

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <div>
          <div className={styles.calendar}>
            <ReactCalendar
              showFixedNumberOfWeeks={true}
              onChange={onChange}
              value={value}
            />
          </div>
          <TimelineSlots
            busySlots={bookings.filter((slot) => {
              return (
                new Date(slot.bookingTime).toISOString().slice(0, 10) ===
                new Date(value).toISOString().slice(0, 10)
              );
            })}
            date={value}
            setSelectedSlot={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
