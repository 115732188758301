import axios from 'axios';
import { useState } from 'react';

import RadioBox from '../RadioBox';
import Button from '../Button';
import Card from '../Card';

import gift from '../../assets/imgs/bgs/gift-1.jpg';
import gift1 from '../../assets/imgs/gift-card/1.png';
import gift2 from '../../assets/imgs/gift-card/2.png';
import gift3 from '../../assets/imgs/gift-card/3.png';
import gift4 from '../../assets/imgs/gift-card/4.png';
import gift5 from '../../assets/imgs/gift-card/5.png';
import gift6 from '../../assets/imgs/gift-card/6.png';
import gift7 from '../../assets/imgs/gift-card/7.png';
import gift8 from '../../assets/imgs/gift-card/8.png';
import gift9 from '../../assets/imgs/gift-card/9.png';

import interac from '../../assets/imgs/interac.svg';
import { ReactComponent as Cash } from '../../assets/imgs/cash.svg';
import settings from '../../config';
import Banner from '../Banner';
import styles from './giftcard.module.css';

const PHONE_REGEX = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const getMaskedPhone = (phone) => {
  const phoneDigits = phone.match(/(\d{3}).*?(\d{3}).*?(\d{4})/) || [];
  const maskedPhone =
    '(' + phoneDigits[1] + ') ' + phoneDigits[2] + '-' + phoneDigits[3];

  return phone && maskedPhone;
};

const giftCards = {
  1: gift1,
  2: gift2,
  3: gift3,
  4: gift4,
  5: gift5,
  6: gift6,
  7: gift7,
  8: gift8,
  9: gift9
};

const GiftCardDesign = ({ giftDetails, setGiftDetails, giftType }) => {
  return (
    <div
      className={styles.giftCard}
      onClick={() => {
        setGiftDetails({
          ...giftDetails,
          giftType
        });
      }}
    >
      {giftDetails.giftType === giftType && (
        <RadioBox
          isSelected={giftDetails.giftType === giftType}
          className={styles.radioBox}
        />
      )}
      <img src={giftCards[giftType]} alt={`gift ${giftType}`} />
    </div>
  );
};

const GiftCard = () => {
  const [giftDetails, setGiftDetails] = useState({
    giftType: 0,
    toEmail: '',
    toName: '',
    toPhone: '',
    fromEmail: '',
    fromName: '',
    fromPhone: '',
    sendDate: '',
    message: '',
    slot: 90,
    paymentMethod: '',
    treatmentType: 'massage',
    discountCode: '',
    price: 140
  });
  const [isSent, setIsSent] = useState(false);

  const handleOnChange = (e) => {
    let value = e.target.value;

    if (e.target.name === 'toPhone' || e.target.name === 'fromPhone') {
      if (PHONE_REGEX.test(e.target.value)) {
        value = getMaskedPhone(e.target.value);
      }
    }

    setGiftDetails({
      ...giftDetails,
      [e.target.name]: value
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const response = await axios.post(`${settings.api}gift-card/create`, {
      ...giftDetails
    });

    if (response.data.statusCode === 200) {
      setIsSent(response.data.data);
    }
  };

  const payment = giftDetails.price;

  return (
    <form onSubmit={onSubmit}>
      <div>
        <Banner title='Give Love' src={gift} hasOverlay isSmall />

        <div className={styles.container}>
          <div>
            {(!isSent && (
              <>
                <div className={styles.heading}>Choose your design</div>
                <div className={styles.grid}>
                  <GiftCardDesign
                    giftType={1}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />
                  <GiftCardDesign
                    giftType={2}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />
                  <GiftCardDesign
                    giftType={3}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />

                  <GiftCardDesign
                    giftType={4}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />
                  <GiftCardDesign
                    giftType={5}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />
                  <GiftCardDesign
                    giftType={6}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />

                  <GiftCardDesign
                    giftType={7}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />
                  <GiftCardDesign
                    giftType={8}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />
                  <GiftCardDesign
                    giftType={9}
                    giftDetails={giftDetails}
                    setGiftDetails={setGiftDetails}
                  />
                </div>

                <div className={styles.heading}>
                  Choose the session type and length
                </div>
                <div className={styles.row}>
                  <div>Massage</div>
                  <div className={styles.grid2}>
                    <Card
                      title={<>60 min - $100</>}
                      description='Personalized, full body focus or specific service for tension areas.'
                      isSelected={
                        giftDetails.slot === 60 &&
                        giftDetails.treatmentType === 'massage' &&
                        giftDetails.discountCode === ''
                      }
                      onClick={() =>
                        setGiftDetails({
                          ...giftDetails,
                          slot: 60,
                          treatmentType: 'massage',
                          discountCode: '',
                          price: 100
                        })
                      }
                    />
                    <Card
                      title={<>90 min - $140</>}
                      description='Allows for further focus on specific areas & wider array of techniques'
                      isSelected={
                        giftDetails.slot === 90 &&
                        giftDetails.treatmentType === 'massage'
                      }
                      onClick={() =>
                        setGiftDetails({
                          ...giftDetails,
                          slot: 90,
                          treatmentType: 'massage',
                          discountCode: '',
                          price: 140
                        })
                      }
                    />
                    <Card
                      title='120 min - $180'
                      description='The ultimate experience'
                      isSelected={
                        giftDetails.slot === 120 &&
                        giftDetails.treatmentType === 'massage'
                      }
                      onClick={() =>
                        setGiftDetails({
                          ...giftDetails,
                          slot: 120,
                          treatmentType: 'massage',
                          discountCode: '',
                          price: 180
                        })
                      }
                    />
                  </div>
                  <div>Foot Reflexology</div>

                  <div className={styles.grid2}>
                    <Card
                      title={<>60 min - $80</>}
                      description='Foot Reflexology'
                      isSelected={
                        giftDetails.slot === 60 &&
                        giftDetails.treatmentType === 'foot reflexology'
                      }
                      onClick={() =>
                        setGiftDetails({
                          ...giftDetails,
                          slot: 60,
                          treatmentType: 'foot reflexology',
                          discountCode: '',
                          price: 80
                        })
                      }
                    />
                    <Card
                      title='90 min - $120'
                      description='The ultimate experience for Foot Reflexology'
                      isSelected={
                        giftDetails.slot === 90 &&
                        giftDetails.treatmentType === 'foot reflexology'
                      }
                      onClick={() =>
                        setGiftDetails({
                          ...giftDetails,
                          slot: 90,
                          treatmentType: 'foot reflexology',
                          discountCode: '',
                          price: 120
                        })
                      }
                    />
                  </div>
                </div>

                <div className={styles.heading}>Who's it for?</div>
                <div>
                  <div className={styles.grid}>
                    <label className={styles.field}>
                      Recipient's Name
                      <input
                        placeholder="Recipient's Name"
                        value={giftDetails.toName}
                        onChange={handleOnChange}
                        name='toName'
                        required
                      />
                    </label>
                    <label className={styles.field}>
                      Recipient's Phone
                      <input
                        placeholder='(123) 456-7890'
                        value={giftDetails.toPhone}
                        onChange={handleOnChange}
                        name='toPhone'
                        type='tel'
                        pattern='[\(]\d{3}[\)][\s]\d{3}[\-]\d{4}'
                        required
                      />
                    </label>
                    <label className={styles.field}>
                      Recipient's Email
                      <input
                        placeholder="Recipient's Email"
                        value={giftDetails.toEmail}
                        onChange={handleOnChange}
                        name='toEmail'
                        type='email'
                      />
                    </label>
                  </div>
                  <div className={styles.grid}>
                    <label className={styles.field}>
                      Sender's Name
                      <input
                        placeholder="Sender's Name"
                        value={giftDetails.fromName}
                        onChange={handleOnChange}
                        name='fromName'
                        required
                      />
                    </label>
                    <label className={styles.field}>
                      Sender's Phone
                      <input
                        placeholder='(123) 456-7890'
                        value={giftDetails.fromPhone}
                        onChange={handleOnChange}
                        name='fromPhone'
                        type='tel'
                        pattern='[\(]\d{3}[\)][\s]\d{3}[\-]\d{4}'
                        required
                      />
                    </label>
                    <label className={styles.field}>
                      Sender's Email
                      <input
                        placeholder="Sender's Email"
                        value={giftDetails.fromEmail}
                        onChange={handleOnChange}
                        name='fromEmail'
                        type='email'
                      />
                    </label>
                  </div>
                  <div className={styles.grid}>
                    <label className={styles.field}>
                      Send date:
                      <input
                        placeholder='yyyy-mm-dd'
                        value={giftDetails.sendDate}
                        name='sendDate'
                        onChange={handleOnChange}
                        type='date'
                        required
                      />
                    </label>
                  </div>
                  <div className={styles.grid}>
                    <label className={styles.field}>
                      Message:
                      <textarea
                        value={giftDetails.message}
                        rows={5}
                        name='message'
                        onChange={handleOnChange}
                      />
                    </label>
                  </div>
                </div>

                <div className={styles.heading}>Payment</div>

                <div className={styles.grid2}>
                  <div
                    className={styles.checkBoxGroup}
                    onClick={() => {
                      setGiftDetails({
                        ...giftDetails,
                        paymentMethod: 'interac'
                      });
                    }}
                  >
                    <div>
                      <RadioBox
                        isSelected={giftDetails.paymentMethod === 'interac'}
                      />
                    </div>
                    <img src={interac} alt='Interac payment' height={70} />
                  </div>
                  <div
                    className={styles.checkBoxGroup}
                    onClick={() => {
                      setGiftDetails({
                        ...giftDetails,
                        paymentMethod: 'cash'
                      });
                    }}
                  >
                    <div>
                      <RadioBox
                        isSelected={giftDetails.paymentMethod === 'cash'}
                      />
                    </div>
                    <Cash
                      className={styles.icon}
                      alt='cash payment'
                      height={70}
                    />
                  </div>
                </div>

                {giftDetails.paymentMethod && (
                  <div className={styles.margin}>
                    {giftDetails.paymentMethod === 'cash' &&
                      `For coordination of a drop-off or pick-up regarding the $${payment} CAD gift card, kindly contact (437) 775-5689 by phone.`}
                    {giftDetails.paymentMethod === 'interac' &&
                      `
                    Kindly transfer the sum of $${payment} CAD for the gift card to the phone number (437) 775-5689. Please ensure to include the recipient's name in the designated message box. Upon successful receipt of the payment, anticipate a one-hour processing time for the delivery of the gift card to the specified email addresses.
            `}
                  </div>
                )}

                <div className={styles.buttonContainer}>
                  <Button
                    type='submit'
                    text='Send'
                    isDisabled={
                      giftDetails.giftType === 0 ||
                      giftDetails.slot === 0 ||
                      !giftDetails.fromName ||
                      !giftDetails.toName ||
                      !giftDetails.toPhone ||
                      !giftDetails.fromPhone ||
                      !giftDetails.sendDate ||
                      !giftDetails.paymentMethod
                    }
                  />
                </div>
              </>
            )) || (
              <div className={`${styles.container} ${styles.fieldGroup}`}>
                <div className={styles.center}>Thank you</div>
                <div>
                  {giftDetails.paymentMethod === 'cash' &&
                    `For coordination of a drop-off or pick-up regarding the $${payment} CAD gift card, kindly contact (437) 775-5689 by phone.`}
                  {giftDetails.paymentMethod === 'interac' &&
                    `Kindly transfer the sum of $${payment} CAD for the gift card to the phone number (437) 775-5689. Please ensure to include the recipient's name in the designated message box. Upon successful receipt of the payment, anticipate a one-hour processing time for the delivery of the gift card to the specified email addresses.`}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default GiftCard;
