import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Session from './components/Session';
import BusinessCard from './components/BusinessCard';
import Layout from './components/Layout';

import 'react-calendar/dist/Calendar.css';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/card' element={<BusinessCard />} />
        <Route path='/session' element={<Session />} />
        <Route path='/*' element={<Layout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
