import styles from './banner.module.css';

const Banner = ({
  src,
  title,
  description,
  isSmall = false,
  hasOverlay = false
}) => {
  const overLayStyle =
    (hasOverlay &&
      `linear-gradient(180deg, rgba(var(--background)) 10%, transparent 40%, transparent 60%, rgba(var(--background)) 100%),`) ||
    '';

  return (
    <div
      className={`${styles.cover} ${isSmall ? styles.small : ''} ${
        !hasOverlay ? styles.hasOverlay : ''
      }`}
      style={{
        backgroundImage: `${overLayStyle}linear-gradient(0deg, rgba(var(--background), 0.5) , rgba(var(--background), 0.5)), url(${src})`
      }}
    >
      {title && <div className={`textPrimary ${styles.heading}`}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default Banner;
