import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

import Button from '../Button';

import settings from '../../config';
import Heading from '../Heading';
import { getDateFromDate } from '../../utils';

const ConsentForm = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    majorConcerns: '',
    name: '',
    signed: '',
    signedDate: new Date().toISOString().slice(0, 19).replace('T', ' '),
    witness: '',
    witnessDate: new Date().toISOString().slice(0, 19).replace('T', ' ')
  });

  useEffect(() => {
    if (code) {
      (async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${settings.api}consent/${code}`);

          if (response.data) {
            setFormData(response.data.data);
            setIsViewOnly(true);
            setIsLoading(false);
          }
        } catch (e) {
          console.error('Error fetching document: ', e);
        }
      })();
    }
  }, [code]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${settings.api}consent/create`,
        formData
      );

      if (response.data.data) {
        navigate(`/forms/health-history/${response.data.data}`);
        setIsLoading(false);
      }
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <div c>
        <Heading title='Consent Form' />
        <div>
          <div>
            <label>
              Do you have any major Concerns?
              <textarea
                value={formData.majorConcerns}
                rows={5}
                onChange={onChange}
                name='majorConcerns'
                required
                disabled={isViewOnly}
              />
            </label>
          </div>

          <div>
            I{' '}
            <input
              placeholder='Your name'
              value={formData.name}
              onChange={onChange}
              name='name'
              disabled={isViewOnly}
              required
            />{' '}
            agree to participate in the health services with the understanding
            that the counselling and treatment / care provided are for my own
            lifestyle guidance, education and /or relaxations.
            <br />
            <br />I understand that it is not for the purpose of diagnosing or
            treating a specific aliment.
            <br />
            <br />I acknowledge that you are not a licensed medical practitioner
            and take full responsibility and release you from the liability with
            respect to any advice or treatment which I may follow or any
            subsequent treatment.
          </div>

          <div>
            <div>
              <div>
                <input
                  placeholder='Signed'
                  value={formData.signed}
                  onChange={onChange}
                  disabled={isViewOnly}
                  name='signed'
                  required
                />
                <input
                  placeholder='Signed Date'
                  value={getDateFromDate(new Date())}
                  onChange={onChange}
                  name='signedDate'
                  required
                  disabled
                />
              </div>
            </div>
          </div>

          <div>
            <div>
              <div>
                <input
                  placeholder='Witness'
                  value={formData.witness}
                  onChange={onChange}
                  disabled={isViewOnly}
                  name='witness'
                />
                <input
                  placeholder='Witness Date'
                  value={getDateFromDate(new Date())}
                  onChange={onChange}
                  name='witnessDate'
                  required
                  disabled
                />
              </div>
            </div>
          </div>

          {!isViewOnly && (
            <div>
              <div>
                <Button
                  type='submit'
                  text='I consent'
                  isDisabled={
                    !(
                      formData.majorConcerns &&
                      formData.name &&
                      formData.signed &&
                      formData.signedDate
                    ) || isLoading
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ConsentForm;
