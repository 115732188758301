import Heading from '../Heading';
import Card from '../Card';

import Button from '../Button';

import styles from './booking.module.css';

const Step4 = ({ bookingDetails, setBookingDetails, prev, next, GiftCard }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        next(6);
      }}
    >
      <Heading title='Tell me about yourself' />
      {GiftCard}
      <div>
        <div>Your Gender</div>
        <div className={styles.grid2}>
          <Card
            title='Male'
            isSelected={bookingDetails.gender === 'male'}
            onClick={() =>
              setBookingDetails({
                ...bookingDetails,
                gender: 'male',
                body: []
              })
            }
          />
          <Card
            title='Female'
            isSelected={bookingDetails.gender === 'female'}
            onClick={() =>
              setBookingDetails({
                ...bookingDetails,
                gender: 'female',
                body: []
              })
            }
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button type='button' text='Back' small action={() => prev(4)} />
          <Button
            type='submit'
            text='Continue'
            isDisabled={!bookingDetails.gender}
          />
        </div>
      </div>
    </form>
  );
};

export default Step4;
