import styles from './button.module.css';

const Button = ({ text, isDisabled = false, action, type }) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={action}
      className={styles.container}
    >
      {text}
    </button>
  );
};

export default Button;
