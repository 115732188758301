import axios from 'axios';

import Heading from '../Heading';
import Button from '../Button';
import settings from '../../config';
import { getDateFromDate, getTimeFromDate } from '../../utils';

import styles from './booking.module.css';

const Step9 = ({ bookingDetails, next, prev, GiftCard, isGift }) => {
  const submit = async (e) => {
    const { phone, email, clientNotes } = bookingDetails || {};

    try {
      const response = await axios.post(`${settings.api}bookings/create`, {
        ...bookingDetails,
        bookingStartTime: new Date(bookingDetails.bookingStartTime)
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19),
        bookingEndTime: new Date(bookingDetails.bookingEndTime)
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19),
        bookingTime: new Date(bookingDetails.bookingTime)
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19)
      });

      if (response.data) {
        const slot = Math.round(
          (bookingDetails.bookingEndTime - bookingDetails.bookingStartTime) /
            60000
        );

        const fullName = `${bookingDetails.firstName} ${bookingDetails.lastName}`;
        const treatmentType = `${bookingDetails.treatmentType}`;
        const gender = `${bookingDetails.gender}`;
        const from = getTimeFromDate(new Date(bookingDetails.bookingStartTime));
        const to = getTimeFromDate(new Date(bookingDetails.bookingEndTime));
        const on = getDateFromDate(new Date(bookingDetails.bookingTime));
        const price = `${bookingDetails.price}`;

        axios.post(`${settings.api}bookings/email`, {
          fullName,
          from,
          to,
          slot,
          phone,
          email,
          clientNotes,
          on,
          treatmentType,
          gender,
          price
        });

        next(10);
      }
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <Heading title='Review, then book' />
      {GiftCard}
      <div>
        <div>
          <div>
            <div>{`${bookingDetails.firstName} ${bookingDetails.lastName}`}</div>
            <div>{`${bookingDetails.email}`}</div>
            <div>{`${bookingDetails.phone}`}</div>
            <hr />
            <div>
              <div>
                <div>
                  {bookingDetails.slot} min{' '}
                  {bookingDetails.treatmentType === 'massage'
                    ? 'Massage'
                    : 'Foot Reflexology'}{' '}
                  Session
                </div>
                <div>
                  {new Date(bookingDetails.bookingTime).toLocaleString(
                    'en-GB',
                    {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric'
                    }
                  )}
                </div>
                <div>
                  {getTimeFromDate(new Date(bookingDetails.bookingStartTime))}
                </div>
              </div>
              {!isGift && <div>${bookingDetails.price}</div>}
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button type='button' text='Back' small action={() => prev(8)} />

            <Button type='submit' text='Confirm' />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Step9;
