import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Banner from '../Banner';
import styles from './booking.module.css';

import calendar from '../../assets/imgs/bgs/calendar.jpg';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';

import settings from '../../config';

import gift1 from '../../assets/imgs/gift-card/1.png';
import gift2 from '../../assets/imgs/gift-card/2.png';
import gift3 from '../../assets/imgs/gift-card/3.png';
import gift4 from '../../assets/imgs/gift-card/4.png';
import gift5 from '../../assets/imgs/gift-card/5.png';
import gift6 from '../../assets/imgs/gift-card/6.png';
import gift7 from '../../assets/imgs/gift-card/7.png';
import gift8 from '../../assets/imgs/gift-card/8.png';
import gift9 from '../../assets/imgs/gift-card/9.png';
import gift10 from '../../assets/imgs/gift-card/10.png';

const gifts = {
  1: gift1,
  2: gift2,
  3: gift3,
  4: gift4,
  5: gift5,
  6: gift6,
  7: gift7,
  8: gift8,
  9: gift9,
  10: gift10
};

const initialState = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  clientNotes: '',
  myNotes: '',
  bookingStatus: 'booked',
  isOriginalBooking: true,
  bookingStartTime: undefined,
  bookingEndTime: undefined,
  gender: undefined,
  bookingTime: new Date(new Date().setHours(0, 0, 0, 0)),
  type: 'booking',
  treatmentType: '',
  isPaid: 0,
  slot: null,
  sessionStartTime: null,
  sessionEndTime: null,
  helpSelection: [],
  healthConditions: [],
  lifeStyle: [],
  body: [],
  isGift: '',
  discountCode: '',
  price: 0
};

const GiftCard = ({ isGift }) => {
  if (!isGift) {
    return null;
  }

  return (
    (!isGift.bookingId && (
      <div>
        <img src={gifts[isGift.giftType]} alt='' />
      </div>
    )) || (
      <div>
        <div>This gift card has been already used!</div>
      </div>
    )
  );
};

const Booking = () => {
  const [step, setStep] = useState(1);
  const { code } = useParams();

  const [bookingDetails, setBookingDetails] = useState(initialState);
  const [isGift, setIsGift] = useState(false);

  useEffect(() => {
    document.getElementById('fb-root').style.display = 'none';

    return () => (document.getElementById('fb-root').style.display = 'block');
  }, []);

  useEffect(() => {
    if (code) {
      (async () => {
        const response = await axios.post(`${settings.api}gift-card/`, {
          code
        });

        if (response.data.statusCode === 200) {
          setIsGift(response.data.data);
          if (!response.data.data.bookingId) {
            setBookingDetails({
              ...initialState,
              slot: response.data.data.slot,
              isGift: code
            });
            setStep(2);
          }
        }
      })();
    }
  }, [code]);

  const next = (step) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  const prev = (step) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  return (
    <>
      {step === 1 && (
        <Banner title='Book Now' src={calendar} hasOverlay isSmall />
      )}
      <div className={styles.container}>
        {step === 1 && (
          <Step1
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            isGift={isGift}
            GiftCard={<GiftCard isGift={isGift} />}
            next={next}
          />
        )}
        <div className={styles.container}>
          {step === 2 && (
            <div>
              <Step7
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 3 && (
            <div>
              <Step2
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 4 && (
            <div>
              <Step3
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 5 && (
            <div>
              <Step4
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 6 && (
            <div>
              <Step5
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 7 && (
            <div>
              <Step6
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 8 && (
            <div>
              <Step8
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 9 && (
            <div>
              <Step9
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
          {step === 10 && (
            <div>
              <Step10
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                next={next}
                prev={prev}
                isGift={isGift}
                GiftCard={<GiftCard isGift={isGift} />}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Booking;
