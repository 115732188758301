import { Link } from 'react-router-dom';

import styles from './footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerLeft}>
        <div className={styles.heading}>
          <Link to='/'>GTA Home Spa</Link>
        </div>
        {/* <div>
          <Link
            target='_gta_home_spa_address'
            to='https://www.google.com/maps/place/1119+Green+St,+Whitby,+ON+L1N+4G8/@43.8688119,-78.9383976,17z'
          >
            1119 Green St, Whitby, ON
            <br /> L1N 4G8, Canada
          </Link>
        </div> */}
        <div>
          <Link to='tel:437-775-5689'>Phone: 437-775-5689</Link>
        </div>

        <div>Email: info@gtahomespa.com</div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.footerRight}>
        <div>
          <Link to='/services'>Services</Link>
        </div>
        <div>
          <Link to='/about-us'>About Us</Link>
        </div>
        <div>
          <Link to='/gallery'>Gallery</Link>
        </div>
        <div>
          <Link to='/reviews'>Reviews</Link>
        </div>
        <div>
          <Link to='/gift-card'>Give Love</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
