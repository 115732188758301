import RadioBox from '../RadioBox';

import confetti from '../../assets/imgs/confetti.png';
import styles from './card.module.css';

const Card = ({
  Icon,
  title,
  description,
  isSelected,
  onClick,
  hasConfetti
}) => {
  return (
    <div
      onClick={onClick}
      className={styles.container}
      style={{
        background: hasConfetti
          ? `linear-gradient(0deg, rgba(var(--background), 0.5) , rgba(var(--background), 0.5)), url(${confetti})`
          : 'rgb(var(--foreground))',
        color: hasConfetti
          ? 'rgb(var(--foreground))'
          : 'rgba(var(--background))'
      }}
    >
      <div className={styles.title}>
        {Icon && <Icon alt='' height={50} />} {title}
      </div>
      {description && <div className={styles.description}>{description}</div>}
      {isSelected && (
        <RadioBox isSelected={isSelected} className={styles.radioBox} />
      )}
    </div>
  );
};

export default Card;
