import Heading from '../Heading';

import { getTimeFromDate } from '../../utils';
import { ReactComponent as Email } from '../../assets/imgs/email.svg';
import { ReactComponent as Phone } from '../../assets/imgs/phone-new.svg';

import styles from './booking.module.css';

const Step10 = ({ bookingDetails, GiftCard }) => {
  return (
    <>
      <Heading title='All set. See you soon!' />
      {GiftCard}

      <div className={styles.container}>
        <div className={styles.day}>
          <div className={styles.subheading}>
            <div className=''>{`${bookingDetails.firstName} ${bookingDetails.lastName}`}</div>
            <div>
              <Phone alt='' height={25} /> &nbsp;
              <span>{`${bookingDetails.phone}`}</span>
            </div>
            <div>
              <Email alt='' height={25} /> &nbsp;
              <span>{`${bookingDetails.email}`}</span>
            </div>
          </div>
          {new Date(bookingDetails.bookingTime).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}{' '}
          @ {getTimeFromDate(new Date(bookingDetails.bookingStartTime))}
          <div>
            {bookingDetails.slot} min{' '}
            {bookingDetails.treatmentType === 'massage'
              ? 'Massage'
              : 'Foot Reflexology'}{' '}
            Session
          </div>
        </div>
      </div>
    </>
  );
};

export default Step10;
