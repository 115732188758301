import axios from 'axios';
import React, { useState, useEffect } from 'react';

import Heading from '../Heading';

import Button from '../Button';
import Slots from '../Slots';

import settings from '../../config';
import styles from './booking.module.css';

import { ReactComponent as Calendar } from '../../assets/imgs/calendar.svg';
import { ReactComponent as Left } from '../../assets/imgs/left.svg';
import { ReactComponent as Right } from '../../assets/imgs/right.svg';
import { ReactComponent as Star } from '../../assets/imgs/star.svg';

const Step7 = ({ bookingDetails, setBookingDetails, next, prev, GiftCard }) => {
  const [bookings, setBookings] = useState([]);
  const [bookingsLoaded, setBookingsLoaded] = useState(false);

  useEffect(() => {
    const getBookings = async () => {
      const response = await axios.post(`${settings.api}bookings/by-date`, {
        date: new Date(bookingDetails.bookingTime).toISOString().slice(0, 10)
      });

      if (response.data.statusCode === 200) {
        setBookings(response.data.data);
        setBookingsLoaded(true);
      }
    };
    getBookings();
  }, [bookingDetails.bookingTime]);

  const prevDay = () => {
    const newDate = new Date(bookingDetails.bookingTime);
    newDate.setDate(newDate.getDate() - 1);
    newDate.setHours(0, 0, 0, 0);

    setBookingDetails({
      ...bookingDetails,
      bookingTime: newDate
    });
    setBookingsLoaded(false);
  };

  const nextDay = () => {
    const newDate = new Date(bookingDetails.bookingTime);
    newDate.setDate(newDate.getDate() + 1);
    newDate.setHours(0, 0, 0, 0);

    setBookingDetails({
      ...bookingDetails,
      bookingTime: newDate
    });
    setBookingsLoaded(false);
  };

  const busySlots = bookings.filter(
    (slot) =>
      new Date(slot.bookingTime).toISOString().slice(0, 10) ===
      new Date(bookingDetails.bookingTime).toISOString().slice(0, 10)
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        next(3);
      }}
    >
      <Heading title='When can you come in?' />
      {GiftCard}
      <div>
        <div className={styles.margin}>
          <span>Massage Session </span>
          {bookingDetails.slot} min
        </div>
        <div className={styles.margin}>
          <span>Day</span>
        </div>
        <div className={styles.rowContainerNoPadding}>
          <button
            className={styles.shapeless}
            type='button'
            onClick={prevDay}
            disabled={
              new Date(bookingDetails.bookingTime).getTime() <=
              new Date().getTime()
            }
          >
            <Left className={styles.arrows} alt='' />
          </button>
          <div className={styles.rowContainerNoPadding}>
            <Calendar alt='' />
            &nbsp;
            <div>
              {new Date(bookingDetails.bookingTime).toLocaleString('en-GB', {
                weekday: 'long',
                month: 'long',
                day: 'numeric'
              })}
            </div>
          </div>
          <div onClick={nextDay}>
            <Right className={styles.arrows} alt='' />
          </div>
        </div>

        <div className={styles.slotsContainer}>
          {bookingDetails.pressure && (
            <div>
              <Star alt='' />
              <span>YOUR PRESSURE PREFERENCE:</span>{' '}
              <span>{bookingDetails.pressure}</span>
            </div>
          )}

          <Slots
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            busySlots={busySlots}
            bookingsLoaded={bookingsLoaded}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button type='button' text='Back' small action={() => prev(1)} />
          <Button
            type='submit'
            text='Continue'
            isDisabled={!bookingDetails.bookingStartTime}
          />
        </div>
      </div>
    </form>
  );
};

export default Step7;
