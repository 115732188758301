import Heading from '../Heading';
import Checkbox from '../Checkbox';

import Button from '../Button';
import styles from './booking.module.css';

const typeOfHelp = [
  'Pain',
  'Tension',
  'Relaxation',
  'Headaches/Migraines',
  'Stress',
  'Muscle Spasm',
  'Injuries',
  'Inflammation',
  'Fatigue',
  'Anxiety',
  'Insomnia'
];

const Step2 = ({ bookingDetails, setBookingDetails, next, prev, GiftCard }) => {
  const helpSelection = bookingDetails.helpSelection || [];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        next(4);
      }}
    >
      <Heading title='So, how can we help?' />
      {GiftCard}

      <div className={styles.grid}>
        {typeOfHelp.map((help) => (
          <div
            className={`${styles.gridItem} ${styles.zebra} ${
              helpSelection.indexOf(help) !== -1 ? styles.selected : ''
            } `}
            key={help}
            onClick={() => {
              if (helpSelection.indexOf(help) === -1) {
                setBookingDetails({
                  ...bookingDetails,
                  helpSelection: [...helpSelection, help]
                });
              } else {
                setBookingDetails({
                  ...bookingDetails,
                  helpSelection: helpSelection.filter((s) => s !== help)
                });
              }
            }}
          >
            <Checkbox isSelected={helpSelection.indexOf(help) !== -1} />{' '}
            <div>{help}</div>
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button type='button' text='Back' small action={() => prev(2)} />
        <Button
          type='submit'
          text='Continue'
          isDisabled={!helpSelection.length}
        />
      </div>
    </form>
  );
};

export default Step2;
