import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { getTimeFromDate } from '../../utils';
import { ReactComponent as BlockIcon } from '../../assets/imgs/block.svg';
import { ReactComponent as CancelIcon } from '../../assets/imgs/cancel.svg';
import { ReactComponent as CheckIcon } from '../../assets/imgs/check.svg';
import { ReactComponent as TrashIcon } from '../../assets/imgs/trash.svg';

import settings from '../../config';

import styles from './timeLineSlots.module.css';

const MovingSlot = ({
  currentSlot,
  bod,
  setFinalSlots,
  index,
  finalSlots,
  setSelectedSlot
}) => {
  if (!currentSlot.bookingEndTime) {
    return null;
  }

  const handleDelete = async (id, slot) => {
    if (!id) {
      await axios.post(`${settings.api}bookings/create`, {
        ...slot,
        bookingStatus: 'deleted',
        bookingStartTime: slot.bookingStartTime,
        bookingEndTime: slot.bookingEndTime,
        bookingTime: slot.bookingTime
      });
    } else {
      await axios.post(`${settings.api}bookings/update`, {
        ...currentSlot,
        bookingStatus: 'deleted',
        treatmentType: '',
        id
      });
    }
  };

  const handleConfirm = async (id, slot) => {
    if (!id) {
      await axios.post(`${settings.api}bookings/create`, {
        ...slot,
        bookingStatus: 'confirmed',
        bookingStartTime: slot.bookingStartTime,
        bookingEndTime: slot.bookingEndTime,
        bookingTime: slot.bookingTime
      });
    } else {
      await axios.post(`${settings.api}bookings/update`, {
        ...currentSlot,
        bookingStatus: 'confirmed',
        id
      });
    }
  };

  const handleBlock = async (id, slot) => {
    if (!id) {
      await axios.post(`${settings.api}bookings/create`, {
        ...slot,
        bookingStatus: 'blocked',
        bookingStartTime: slot.bookingStartTime,
        bookingEndTime: slot.bookingEndTime,
        bookingTime: slot.bookingTime
      });
    } else {
      await axios.post(`${settings.api}bookings/update`, {
        ...currentSlot,
        bookingStatus: 'blocked',
        id
      });
    }
  };

  const handleCancel = async (id, slot) => {
    if (!id) {
      await axios.post(`${settings.api}bookings/create`, {
        ...slot,
        bookingStatus: 'cancelled',
        bookingStartTime: slot.bookingStartTime,
        bookingEndTime: slot.bookingEndTime,
        bookingTime: slot.bookingTime
      });
    } else {
      await axios.post(`${settings.api}bookings/update`, {
        ...currentSlot,
        bookingStatus: 'cancelled',
        id
      });
    }
  };

  const title =
    currentSlot.type === 'blocked'
      ? 'Blocked'
      : `${currentSlot.firstName ? currentSlot.firstName : ''} - ${
          currentSlot.lastName ? currentSlot.lastName : ''
        }`;

  const diff = Math.round(
    (new Date(currentSlot.bookingEndTime) -
      new Date(currentSlot.bookingStartTime)) /
      60000
  );

  return (
    <div
      className={styles.slot}
      style={{
        height: diff - 3,
        top: Math.round(
          new Date(
            new Date(currentSlot.bookingStartTime.replace(' ', 'T') + 'Z') - bod
          ) / 60000
        ),
        backgroundColor:
          currentSlot.bookingStatus === 'confirmed'
            ? 'green'
            : currentSlot.bookingStatus === 'blocked'
            ? 'blue'
            : currentSlot.bookingStatus === 'deleted'
            ? 'grey'
            : currentSlot.bookingStatus === 'cancelled'
            ? 'red'
            : 'yellow'
      }}
      onClick={() => {
        setSelectedSlot(currentSlot);
      }}
    >
      <div>{`${currentSlot.firstName ? title : ''} ${diff} minutes`}</div>
      <div>
        <CheckIcon
          alt=''
          height={15}
          onClick={() => {
            handleConfirm(finalSlots[index].id, finalSlots[index]);
            setFinalSlots(
              finalSlots.map((fs, i) =>
                i === index ? { ...fs, bookingStatus: 'confirmed' } : fs
              )
            );
          }}
        />
        <BlockIcon
          alt=''
          height={15}
          onClick={() => {
            handleBlock(finalSlots[index].id, finalSlots[index]);
            setFinalSlots(
              finalSlots.map((fs, i) =>
                i === index ? { ...fs, bookingStatus: 'blocked' } : fs
              )
            );
          }}
        />
        <CancelIcon
          alt=''
          height={15}
          onClick={() => {
            handleCancel(finalSlots[index].id, finalSlots[index]);
            setFinalSlots(
              finalSlots.map((fs, i) =>
                i === index ? { ...fs, bookingStatus: 'cancelled' } : fs
              )
            );
          }}
        />
        <TrashIcon
          alt=''
          height={15}
          onClick={() => {
            handleDelete(finalSlots[index].id, finalSlots[index]);
            setFinalSlots(
              finalSlots.map((fs, i) =>
                i === index ? { ...fs, bookingStatus: 'deleted' } : fs
              )
            );
          }}
        />
      </div>
    </div>
  );
};

const OldMovingSlot = ({ currentSlot }) => {
  if (!currentSlot.bookingEndTime) {
    return null;
  }

  const diff = Math.round(
    (new Date(currentSlot.bookingEndTime) -
      new Date(currentSlot.bookingStartTime)) /
      60000
  );

  return (
    <div
      className={styles.potentialSlot}
      style={{
        height: diff - 3
      }}
    >
      Blocked - {`${diff} minutes`}
    </div>
  );
};

const TimelineSlots = ({ date, busySlots = [], setSelectedSlot }) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [potentialSlot, setPotentialSlot] = useState({});
  const [finalSlots, setFinalSlots] = useState([]);

  useEffect(() => {
    setFinalSlots(busySlots);
  }, [busySlots]);

  let bod = new Date(new Date(date).setHours(9, 0, 0, 0));
  let eod = new Date(new Date(date).setHours(21, 0, 0, 0));

  const diff = Math.round((eod - bod) / 60000);
  const slots = new Array(diff / 30).fill(0).map((s, i) => ({
    bookingStartTime: new Date(new Date(bod).setHours(9, i * 30, 0, 0))
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19),
    bookingEndTime: new Date(new Date(bod).setHours(9, (i + 1) * 30, 0, 0))
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19),
    bookingTime: new Date(bod).toISOString().replace('T', ' ').slice(0, 19),
    isPaid: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    type: 'blocked',
    isOriginalBooking: 1,
    myNotes: '',
    clientNotes: '',
    sessionStartTime: null,
    sessionEndTime: null,
    pressure: null,
    body: null,
    healthConditions: null,
    helpSelection: null,
    lifeStyle: null,
    isGift: '',
    treatmentType: '',
    discountCode: '',
    price: 0
  }));

  const handleMouseDown = (slot) => {
    setIsMouseDown(true);
    setPotentialSlot(slot);
  };

  const handleMouseMove = (slot) => {
    if (isMouseDown) {
      setPotentialSlot({
        ...potentialSlot,
        bookingEndTime: slot.bookingEndTime,
        bookingStatus: 'blocked',
        isPaid: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        type: 'blocked',
        isOriginalBooking: 1,
        myNotes: '',
        clientNotes: '',
        sessionStartTime: null,
        sessionEndTime: null,
        pressure: null,
        body: null,
        healthConditions: null,
        helpSelection: null,
        lifeStyle: null,
        isGift: '',
        treatmentType: '',
        discountCode: '',
        price: 0
      });
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    setFinalSlots([...finalSlots, potentialSlot]);
    setPotentialSlot({});
  };

  return (
    <div className={styles.container}>
      {slots.map((slot, index) => {
        return (
          <div
            key={slot.bookingStartTime}
            className={styles.time}
            style={{
              height: 30
            }}
          >
            <div className={styles.timeLabel}>
              {index % 2 === 0 &&
                getTimeFromDate(
                  new Date(slot.bookingStartTime.replace(' ', 'T') + 'Z')
                )}
            </div>

            <div
              className={styles.movingSlot}
              onMouseDown={() => handleMouseDown(slot)}
              onMouseEnter={(e) => {
                handleMouseMove(slot);
              }}
              onMouseUp={() => handleMouseUp()}
            >
              {potentialSlot.bookingStartTime === slot.bookingStartTime && (
                <OldMovingSlot currentSlot={potentialSlot} />
              )}
            </div>
          </div>
        );
      })}
      {finalSlots.map((s, i) => {
        return (
          <MovingSlot
            key={i}
            currentSlot={s}
            index={i}
            bod={bod}
            setFinalSlots={setFinalSlots}
            finalSlots={finalSlots}
            setSelectedSlot={setSelectedSlot}
          />
        );
      })}
    </div>
  );
};

export default TimelineSlots;
