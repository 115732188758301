import { useEffect, useState, useRef } from 'react';

const Time = ({ className }) => {
  const [currentTime, setCurrentTime] = useState();
  const timer = useRef();

  useEffect(() => {
    timer.current = setInterval(() => {
      const now = new Date();

      let hour = (
        now.getHours() > 12 ? now.getHours() - 12 : now.getHours()
      ).toString();
      hour = hour.length === 1 ? '0' + hour : hour;
      let minutes = now.getMinutes().toString();
      minutes = minutes.length === 1 ? '0' + minutes : minutes;
      let seconds = now.getSeconds().toString();
      seconds = seconds.length === 1 ? '0' + seconds : seconds;

      setCurrentTime(`${hour}:${minutes}:${seconds}`);
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return <div>{currentTime}</div>;
};

export default Time;
