import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Button from '../Button';

import settings from '../../config';
import Heading from '../Heading';
import Checkbox from '../Checkbox';
const HealthHistory = () => {
  const { code } = useParams();

  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    address: '',
    postalCode: '',
    phoneHome: '',
    phoneCell: '',
    occupation: '',
    emergencyContact: '',
    primaryComplaint: '',
    familyPhysician: '',
    phonePhysician: '',
    referral: false,
    referralBy: '',
    musclesJointsNerves: '',
    respiratory: '',
    contagiousDiseases: '',
    cardiovascular: '',
    eyesEarThroat: '',
    gastrointestinal: '',
    genitoUrinary: '',
    gynecological: '',
    skin: '',
    surgeries: '',
    cancers: false,
    cancersNotes: '',
    prominentDiseases: false,
    prominentDiseasesNotes: '',
    medications: '',
    pinsWiresScrewsPlates: '',
    pastReflexology: false,
    prosthetics: false,
    prostheticsNotes: '',
    pregnant: false,
    otherTherapies: '',
    reflexologyReasons: '',
    majorStressors: '',
    moreNotes: '',
    code
  });

  const musclesJointsNerves = [
    'Headaches',
    'Head trauma/ Concussion',
    'Neck pain/ Injury',
    'Whiplash',
    'Back pain',
    'Shoulder pain/ Injury',
    'Arm pain/ Weakness/ Tingling',
    'Degenerative discs',
    'Scoliosis',
    'Sciatic/ Hip pain',
    'Leg pain/ Weakness/ Tingling',
    'Knee pain/ Injury',
    'Foot pain/ Injury',
    'Osteoarthritis/ Rheumatoid arthritis',
    'Tendinitis/ Bursitis',
    'Osteoporosis',
    'Fractures/ Disolcations',
    'Stiff/ Swollen joins',
    'Fibromyalgia',
    'Multiple sclerosis',
    'Epilepsy',
    'Shingles',
    'Nervousness/ Depression'
  ];

  const respiratory = [
    'Asthma/ Bronchitis',
    'Chronic cough',
    'Shortness of breadth',
    'Allergies/ Sinus problems',
    'Emphysema'
  ];

  const contagiousDiseases = ['HIV/ AIDS', 'Hepatitis A/ B/ C'];

  const cardiovascular = [
    'High/ Low blood pressure',
    'Heart attack/ stroke',
    'Chest pain/ Angina',
    'Varicose veins/ Phlebitis',
    'Cold hands/ Feet',
    'Poor healing/ Bruising',
    'Diabetes',
    'Anemia',
    'Fatigue'
  ];

  const eyesEarThroat = [
    'Dizziness',
    'Pain behind the eyes',
    'Blurred vision',
    'Ringing in the ears',
    'Recent dental work',
    'TMJ/ Jaw cracking',
    'Clenching/ Grinding teeth'
  ];

  const gastrointestinal = [
    'Nausea',
    'Constipation/ Diarrhea',
    'Ulcers',
    'Hiatus hernia',
    'Irritable bowel syndrome (IBS)',
    "Cronh's Disease",
    'Diverticulitis',
    'Hypoglycemia'
  ];

  const genitoUrinary = [
    'Painful/ Frequent urination',
    'Kidney/ Gall stones',
    'Bladder infection',
    'Incontinence'
  ];

  const gynecological = [
    'Current pregnant',
    'PMS',
    'Menopause',
    'Fibrocystic breasts'
  ];

  const skin = ['Sensitive', 'Rash/ Eruption', 'Cold sores'];

  const surgeries = ['Abdominal', 'Heart/ Chest', 'Pelvic', 'Foot/ Hands'];

  const otherTherapies = [
    'Chiropractic treatment',
    'Physiotherapy',
    'Acupuncture'
  ];

  const reflexologyReasons = [
    'Relaxation',
    'Injury',
    'Low stress/ Anexiety',
    'Moderate stress/ Anexiety',
    'High stress/ Anexiety',
    'Low pain',
    'Moderate pain',
    'High pain'
  ];

  useEffect(() => {
    if (code) {
      (async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${settings.api}health-history/${code}`
          );

          if (response.data) {
            setFormData(response.data.data);
            setIsViewOnly(true);
            setIsLoading(false);
          }
        } catch (e) {
          console.error('Error fetching document: ', e);
          setIsLoading(false);
        }
      })();
    }
  }, [code]);

  const handleReferrals = () => {
    if (!isViewOnly) {
      setFormData({
        ...formData,
        referral: !formData.referral
      });
    }
  };

  const handleCancers = () => {
    if (!isViewOnly) {
      setFormData({
        ...formData,
        cancers: !formData.cancers
      });
    }
  };

  const handleProminentDiseases = () => {
    if (!isViewOnly) {
      setFormData({
        ...formData,
        prominentDiseases: !formData.prominentDiseases
      });
    }
  };

  const handlePastReflexology = () => {
    if (!isViewOnly) {
      setFormData({
        ...formData,
        pastReflexology: !formData.pastReflexology
      });
    }
  };

  const handleProsthetics = () => {
    if (!isViewOnly) {
      setFormData({
        ...formData,
        prosthetics: !formData.prosthetics
      });
    }
  };

  const handlePregnant = () => {
    if (!isViewOnly) {
      setFormData({
        ...formData,
        pregnant: !formData.pregnant
      });
    }
  };

  const onChange = (e) => {
    if (!isViewOnly) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${settings.api}health-history/create`,
        formData
      );

      if (response.data) {
        setIsSent(true);
        setIsLoading(false);
      }
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <>
      {(isSent && (
        <div>
          <div>
            <Heading title='All set. Enjoy your session' />
            <div>
              <div>
                <div>Forms was sent successfully</div>
              </div>
            </div>
          </div>
        </div>
      )) || (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <div>
            <Heading title='Health History' />
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div>
                        <label>
                          Name
                          <input
                            disabled={isViewOnly}
                            placeholder='Name'
                            value={formData.name}
                            name='name'
                            onChange={onChange}
                            type='text'
                            required
                          />
                        </label>
                        <label>
                          Birth Date
                          <input
                            disabled={isViewOnly}
                            placeholder='mm-dd-yyyy'
                            value={formData.birthDate}
                            name='birthDate'
                            onChange={onChange}
                            type='text'
                            pattern='[0-9]{2}-[0-9]{2}-[0-9]{4}'
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Address
                          <input
                            disabled={isViewOnly}
                            placeholder='Address'
                            value={formData.address}
                            name='address'
                            onChange={onChange}
                            type='text'
                            required
                          />
                        </label>
                        <label>
                          Postal Code
                          <input
                            disabled={isViewOnly}
                            placeholder='L1N4G8'
                            value={formData.postalCode}
                            onChange={onChange}
                            type='text'
                            name='postalCode'
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Phone (Cell):
                          <input
                            disabled={isViewOnly}
                            placeholder='Phone (Cell)'
                            value={formData.phoneCell}
                            rows={5}
                            onChange={onChange}
                            name='phoneCell'
                            type='tel'
                            pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
                            required
                          />
                        </label>
                        <label>
                          Phone (Home):
                          <input
                            disabled={isViewOnly}
                            placeholder='Phone (Home)'
                            value={formData.phoneHome}
                            rows={5}
                            onChange={onChange}
                            name='phoneHome'
                            type='tel'
                            pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Occupation
                          <input
                            disabled={isViewOnly}
                            placeholder='Occupation'
                            value={formData.occupation}
                            rows={5}
                            onChange={onChange}
                            name='occupation'
                            type='text'
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Emergency Contact
                          <input
                            disabled={isViewOnly}
                            placeholder='Emergency Contact'
                            value={formData.emergencyContact}
                            name='emergencyContact'
                            onChange={onChange}
                            type='tel'
                            pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
                            required
                          />
                        </label>
                        <label>
                          Primary Complaint
                          <input
                            disabled={isViewOnly}
                            placeholder='Primary Complaint'
                            value={formData.primaryComplaint}
                            onChange={onChange}
                            name='primaryComplaint'
                            type='text'
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Family Physician
                          <input
                            disabled={isViewOnly}
                            placeholder='Family Physician Contact'
                            value={formData.familyPhysician}
                            name='familyPhysician'
                            onChange={onChange}
                            type='text'
                          />
                        </label>
                        <label>
                          Family Physician Phone
                          <input
                            disabled={isViewOnly}
                            placeholder='Family Physician Phone'
                            value={formData.phonePhysician}
                            onChange={onChange}
                            name='phonePhysician'
                            type='tel'
                            pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
                          />
                        </label>
                      </div>
                      <div>
                        <label onClick={handleReferrals} name='referral'>
                          <Checkbox isSelected={!!formData.referral} />
                          Were you <br /> referred?
                        </label>
                        {(formData.referral && (
                          <label>
                            Referral By
                            <input
                              disabled={isViewOnly}
                              placeholder='Referral By'
                              value={formData.referralBy}
                              onChange={onChange}
                              name='referralBy'
                              type='text'
                              required
                            />
                          </label>
                        )) ||
                          null}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>Muscle/ Joints/ Nerves</div>
                    <div>
                      {musclesJointsNerves.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (
                                formData.musclesJointsNerves.indexOf(item) ===
                                -1
                              ) {
                                setFormData({
                                  ...formData,
                                  musclesJointsNerves: [
                                    ...formData.musclesJointsNerves,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  musclesJointsNerves:
                                    formData.musclesJointsNerves.filter(
                                      (s) => s !== item
                                    )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.musclesJointsNerves.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Respiratory</div>
                    <div>
                      {respiratory.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (formData.respiratory.indexOf(item) === -1) {
                                setFormData({
                                  ...formData,
                                  respiratory: [...formData.respiratory, item]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  respiratory: formData.respiratory.filter(
                                    (s) => s !== item
                                  )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.respiratory.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Contagious Diseases</div>
                    <div>
                      {contagiousDiseases.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (
                                formData.contagiousDiseases.indexOf(item) === -1
                              ) {
                                setFormData({
                                  ...formData,
                                  contagiousDiseases: [
                                    ...formData.contagiousDiseases,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  contagiousDiseases:
                                    formData.contagiousDiseases.filter(
                                      (s) => s !== item
                                    )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.contagiousDiseases.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Cardiovascular</div>
                    <div>
                      {cardiovascular.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (
                                formData.cardiovascular.indexOf(item) === -1
                              ) {
                                setFormData({
                                  ...formData,
                                  cardiovascular: [
                                    ...formData.cardiovascular,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  cardiovascular:
                                    formData.cardiovascular.filter(
                                      (s) => s !== item
                                    )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.cardiovascular.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Eyes/ Ear/ Throat</div>
                    <div>
                      {eyesEarThroat.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (formData.eyesEarThroat.indexOf(item) === -1) {
                                setFormData({
                                  ...formData,
                                  eyesEarThroat: [
                                    ...formData.eyesEarThroat,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  eyesEarThroat: formData.eyesEarThroat.filter(
                                    (s) => s !== item
                                  )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.eyesEarThroat.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Gastrointestinal</div>
                    <div>
                      {gastrointestinal.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (
                                formData.gastrointestinal.indexOf(item) === -1
                              ) {
                                setFormData({
                                  ...formData,
                                  gastrointestinal: [
                                    ...formData.gastrointestinal,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  gastrointestinal:
                                    formData.gastrointestinal.filter(
                                      (s) => s !== item
                                    )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.gastrointestinal.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Genito/ Urinary</div>
                    <div>
                      {genitoUrinary.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (formData.genitoUrinary.indexOf(item) === -1) {
                                setFormData({
                                  ...formData,
                                  genitoUrinary: [
                                    ...formData.genitoUrinary,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  genitoUrinary: formData.genitoUrinary.filter(
                                    (s) => s !== item
                                  )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.genitoUrinary.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Gynecological</div>
                    <div>
                      {gynecological.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (formData.gynecological.indexOf(item) === -1) {
                                setFormData({
                                  ...formData,
                                  gynecological: [
                                    ...formData.gynecological,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  gynecological: formData.gynecological.filter(
                                    (s) => s !== item
                                  )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.gynecological.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Skin</div>
                    <div>
                      {skin.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (formData.skin.indexOf(item) === -1) {
                                setFormData({
                                  ...formData,
                                  skin: [...formData.skin, item]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  skin: formData.skin.filter((s) => s !== item)
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={formData.skin.indexOf(item) !== -1}
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>Surgeries</div>
                    <div>
                      {surgeries.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (formData.surgeries.indexOf(item) === -1) {
                                setFormData({
                                  ...formData,
                                  surgeries: [...formData.surgeries, item]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  surgeries: formData.surgeries.filter(
                                    (s) => s !== item
                                  )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={formData.surgeries.indexOf(item) !== -1}
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>
                      <label onClick={handleCancers} name='cancers'>
                        <Checkbox isSelected={!!formData.cancers} />
                        Cancers?
                      </label>
                      {(formData.cancers && (
                        <label>
                          Details
                          <input
                            disabled={isViewOnly}
                            placeholder='Details'
                            value={formData.cancersNotes}
                            onChange={onChange}
                            name='cancersNotes'
                            type='text'
                            required
                          />
                        </label>
                      )) ||
                        null}
                    </div>
                  </div>

                  <div>
                    <label
                      onClick={handleProminentDiseases}
                      name='prominentDiseases'
                    >
                      <Checkbox isSelected={!!formData.prominentDiseases} />
                      Are there any prominent diseases in your family? (e.g.
                      heart disease, cancer, diabetes, etc...)
                    </label>
                    {(formData.prominentDiseases && (
                      <div>
                        <label>
                          Details
                          <textarea
                            disabled={isViewOnly}
                            value={formData.prominentDiseasesNotes}
                            rows={5}
                            onChange={onChange}
                            name='prominentDiseasesNotes'
                          />
                        </label>
                      </div>
                    )) ||
                      null}
                  </div>

                  <div>
                    <div>
                      <label>
                        List all current medications including aspirin or other
                        over the counter medications (with dates)
                        <textarea
                          disabled={isViewOnly}
                          value={formData.medications}
                          rows={5}
                          onChange={onChange}
                          name='medications'
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>
                        List all pins, wires, screws, plates from previous
                        surgeries
                        <textarea
                          disabled={isViewOnly}
                          value={formData.pinsWiresScrewsPlates}
                          rows={5}
                          onChange={onChange}
                          name='pinsWiresScrewsPlates'
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label
                        onClick={handlePastReflexology}
                        name='pastReflexology'
                      >
                        <Checkbox isSelected={!!formData.pastReflexology} />
                        Have you ever had Reflexology treatment before?
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label onClick={handleProsthetics} name='prosthetics'>
                        <Checkbox isSelected={!!formData.prosthetics} />
                        Do you use prosthetics?
                      </label>
                      {(formData.prosthetics && (
                        <label>
                          Where?
                          <input
                            disabled={isViewOnly}
                            placeholder='Details'
                            value={formData.prostheticsNotes}
                            onChange={onChange}
                            name='prostheticsNotes'
                            type='text'
                            required
                          />
                        </label>
                      )) ||
                        null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <label onClick={handlePregnant} name='pregnant'>
                        <Checkbox isSelected={!!formData.pregnant} />
                        Are you currently pregnant?
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>Have you received other therapies?</div>
                    <div>
                      {otherTherapies.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (
                                formData.otherTherapies.indexOf(item) === -1
                              ) {
                                setFormData({
                                  ...formData,
                                  otherTherapies: [
                                    ...formData.otherTherapies,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  otherTherapies:
                                    formData.otherTherapies.filter(
                                      (s) => s !== item
                                    )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.otherTherapies.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>What brings you for a reflexology treatment?</div>
                    <div>
                      {reflexologyReasons.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (
                                formData.reflexologyReasons.indexOf(item) === -1
                              ) {
                                setFormData({
                                  ...formData,
                                  reflexologyReasons: [
                                    ...formData.reflexologyReasons,
                                    item
                                  ]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  reflexologyReasons:
                                    formData.reflexologyReasons.filter(
                                      (s) => s !== item
                                    )
                                });
                              }
                            }
                          }}
                        >
                          <Checkbox
                            isSelected={
                              formData.reflexologyReasons.indexOf(item) !== -1
                            }
                          />{' '}
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>
                        Do you have any major stressors?
                        <textarea
                          disabled={isViewOnly}
                          value={formData.majorStressors}
                          rows={5}
                          onChange={onChange}
                          name='majorStressors'
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>
                        Is there anything else that you would want to share
                        about your health?
                        <textarea
                          disabled={isViewOnly}
                          value={formData.moreNotes}
                          rows={5}
                          onChange={onChange}
                          name='moreNotes'
                        />
                      </label>
                    </div>
                  </div>

                  {!isViewOnly && (
                    <div>
                      <div>
                        <Button
                          type='submit'
                          text='Submit'
                          isDisabled={
                            !(
                              formData.name &&
                              formData.birthDate &&
                              formData.address &&
                              formData.postalCode &&
                              formData.phoneCell &&
                              formData.occupation &&
                              formData.emergencyContact &&
                              formData.primaryComplaint &&
                              ((formData.referral && formData.referralBy) ||
                                !formData.referral)
                            ) || isLoading
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default HealthHistory;
