import { ReactComponent as Logo } from '../../assets/imgs/logo.svg';
import { ReactComponent as Email } from '../../assets/imgs/email.svg';
import { ReactComponent as Website } from '../../assets/imgs/website.svg';
import { ReactComponent as Phone } from '../../assets/imgs/phone-new.svg';
import { ReactComponent as Home } from '../../assets/imgs/location.svg';
import { Link } from 'react-router-dom';

import styles from './businessCard.module.css';

const BusinessCard = () => {
  return (
    <div className={styles.container}>
      <Link to='/'>
        <div className={styles.flexContainer}>
          <div className={styles.content}>
            <Logo height={50} alt='GTA Home Spa' />
            <div className={styles.heading}>GTA Home Spa</div>
            <div>The Perfect Way To Relax</div>
          </div>
          <div className={styles.content}>
            <div className={styles.heading}>Mina Hanna</div>
            <div className={styles.details}>
              <div className={styles.field}>
                <Home height={20} />
                <span>1119 Green St, Whitby, ON, L1N 4G8</span>
              </div>
              <div className={styles.field}>
                <Phone height={20} />
                <span>(437) 775-5689</span>
              </div>
              <div className={styles.field}>
                <Email height={20} />
                <span>info@gtahomespa.com</span>
              </div>
              <div className={styles.field}>
                <Website height={20} />
                <span>gtahomespa.com</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BusinessCard;
