import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Button from '../Button';

import settings from '../../config';
import Heading from '../Heading';
import chart from '../../assets/imgs/foot-reflexology-chart.jpg';

const TreatmentForm = ({ view }) => {
  const { treatmentId, code = null } = useParams();
  const chartRef = useRef(null);
  const [isSent, setIsSent] = useState(false);
  const [isViewOnly] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    sessionDate: '',
    sessionNumber: 0,
    clientFeelingLastTreatment: '',
    clientFeelingToday: '',
    clientObservation: '',
    leftFoot: '',
    rightFoot: '',
    treatmentFindings: '',
    workedReflexes: '',
    results: '',
    clientComments: '',
    treatmentObservation: '',
    nextTreatmentPlan: '',
    footChart: [],
    code
  });
  const [allTreatments, getAllTreatments] = useState([]);

  useEffect(() => {
    if (treatmentId) {
      (async () => {
        try {
          const response = await axios.get(
            `${settings.api}treatment/${treatmentId}`
          );

          if (response.data) {
            setFormData({
              ...response.data.data,
              footChart: JSON.parse(response.data.data.footChart)
            });
            setIsEditable(true);
          }
        } catch (e) {
          console.error('Error fetching document: ', e);
        }
      })();
    } else if (code) {
      (async () => {
        try {
          const response = await axios.post(`${settings.api}treatment/view`, {
            code
          });

          if (response.data) {
            getAllTreatments(response.data.data);
            setFormData({
              code,
              name: response.data.data[0].name,
              sessionDate: `${
                (new Date().getMonth() + 1).toString().length <= 1
                  ? '0' + (new Date().getMonth() + 1).toString()
                  : new Date().getMonth() + 1
              }-${
                new Date().getDate().toString().length < 2
                  ? '0' + new Date().getDate()
                  : new Date().getDate()
              }-${new Date().getFullYear()}`,
              clientFeelingLastTreatment: '',
              clientFeelingToday: '',
              clientObservation: '',
              leftFoot: '',
              rightFoot: '',
              treatmentFindings: '',
              workedReflexes: '',
              results: '',
              clientComments: '',
              treatmentObservation: '',
              nextTreatmentPlan: '',
              footChart: [],
              sessionNumber: response.data.data.length + 1
            });
          }
        } catch (e) {
          console.error('Error fetching document: ', e);
        }
      })();
    }
  }, [treatmentId, code]);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const submit = async () => {
    try {
      const response = await axios.post(
        `${settings.api}treatment/${(isEditable && 'update') || 'create'}`,
        formData
      );

      if (response.data) {
        setIsSent(true);
      }
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const [currentColor, setCurrentColor] = useState(null);

  const handleOnClick = (e) => {
    if (!isViewOnly) {
      var bounds = chartRef.current.getBoundingClientRect();
      var x = e.clientX - bounds.left - 4;
      var y = e.clientY - bounds.top - 4;

      setFormData({
        ...formData,
        footChart: [
          ...formData.footChart,
          {
            left: `${(x * 100) / bounds.width}%`,
            top: `${(y * 100) / bounds.height}%`,
            backgroundColor: currentColor
          }
        ]
      });
    }
  };

  return (
    <>
      {(isSent && (
        <div>
          <Heading title='All set. Enjoy your session' />
          <div>
            <div>
              <div>Forms was sent successfully</div>
            </div>
          </div>
        </div>
      )) || (
        <>
          {allTreatments.map((treatmentData) => {
            return (
              <div key={`treatment-${treatmentData.treatmentFormId}`}>
                <Heading title='Treatment Form' />
                <div>
                  <div>
                    <div>
                      <div>
                        <label>
                          Name
                          <input
                            disabled
                            placeholder='Name'
                            value={treatmentData.name}
                            name='name'
                            onChange={onChange}
                            type='text'
                            required
                          />
                        </label>
                        <label>
                          Date
                          <input
                            disabled
                            placeholder='mm-dd-yyyy'
                            value={treatmentData.sessionDate}
                            name='sessionDate'
                            onChange={onChange}
                            type='text'
                            pattern='[0-9]{2}-[0-9]{2}-[0-9]{4}'
                            required
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div>
                        <label>
                          Session Number
                          <input
                            disabled
                            placeholder='Session Number'
                            value={treatmentData.sessionNumber}
                            name='sessionNumber'
                            onChange={onChange}
                            type='text'
                            pattern='[0-9]*'
                            required
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>
                        How did client feel last treatment:
                        <textarea
                          value={treatmentData.clientFeelingLastTreatment}
                          rows={5}
                          onChange={onChange}
                          name='clientFeelingLastTreatment'
                          required
                          disabled
                        />
                      </label>
                    </div>

                    <div>
                      <label>
                        How does client feel today:
                        <textarea
                          value={treatmentData.clientFeelingToday}
                          rows={5}
                          onChange={onChange}
                          name='clientFeelingToday'
                          required
                          disabled
                        />
                      </label>
                    </div>

                    <div>
                      <label>
                        Observation of client:
                        <textarea
                          value={treatmentData.clientObservation}
                          rows={5}
                          onChange={onChange}
                          name='clientObservation'
                          required
                          disabled
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>Foot Observation</div>

                    <div>
                      <label>
                        Left foot
                        <textarea
                          disabled
                          placeholder='Left foot'
                          value={treatmentData.leftFoot}
                          name='leftFoot'
                          onChange={onChange}
                          type='text'
                          required
                        />
                      </label>
                      <label>
                        Right foot
                        <textarea
                          disabled
                          placeholder='Right foot'
                          value={treatmentData.rightFoot}
                          name='rightFoot'
                          onChange={onChange}
                          type='text'
                          pattern='[0-9]{2}-[0-9]{2}-[0-9]{4}'
                          required
                        />
                      </label>
                    </div>

                    <div>
                      <label>
                        Findings during treatment:
                        <textarea
                          value={treatmentData.treatmentFindings}
                          rows={5}
                          onChange={onChange}
                          name='treatmentFindings'
                          required
                          disabled
                        />
                      </label>
                    </div>

                    <div>
                      <label>
                        Reflexes that were worked on:
                        <textarea
                          value={treatmentData.workedReflexes}
                          rows={5}
                          onChange={onChange}
                          name='workedReflexes'
                          required
                          disabled
                        />
                      </label>
                    </div>

                    <div>
                      <label>
                        Results:
                        <textarea
                          value={treatmentData.results}
                          rows={5}
                          onChange={onChange}
                          name='results'
                          required
                          disabled
                        />
                      </label>
                    </div>

                    <div>
                      <label>
                        Clients comments:
                        <textarea
                          value={treatmentData.clientComments}
                          rows={5}
                          onChange={onChange}
                          name='clientComments'
                          required
                          disabled
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>
                        Observation end of treatment:
                        <textarea
                          value={treatmentData.treatmentObservation}
                          rows={5}
                          onChange={onChange}
                          name='treatmentObservation'
                          required
                          disabled
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label>
                        Plan for next treatment:
                        <textarea
                          value={treatmentData.nextTreatmentPlan}
                          rows={5}
                          onChange={onChange}
                          name='nextTreatmentPlan'
                          required
                          disabled
                        />
                      </label>
                    </div>
                  </div>

                  <div>
                    <div onClick={() => {}}>
                      <div />
                      Tenderness
                    </div>
                    <div onClick={() => {}}>
                      <div />
                      Blister
                    </div>
                    <div onClick={() => {}}>
                      <div />
                      Calluses
                    </div>
                    <div onClick={() => {}}>
                      <div />
                      Disturbance
                    </div>
                    <div onClick={() => {}}>
                      <div />
                      Puffiness
                    </div>
                    <div onClick={() => {}}>
                      <div />
                      Inflammation
                    </div>
                    <div onClick={() => {}}>
                      <div />
                      Scars
                    </div>
                  </div>

                  <div>
                    {JSON.parse(treatmentData.footChart).map((area, index) => (
                      <div key={index} style={area} />
                    ))}
                    <img src={chart} alt='chart' onClick={() => {}} />
                  </div>
                </div>
              </div>
            );
          })}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <div>
              <Heading title='Treatment Form' />
              <div>
                <div>
                  <div>
                    <div>
                      <label>
                        Name
                        <input
                          disabled={isViewOnly}
                          placeholder='Name'
                          value={formData.name}
                          name='name'
                          onChange={onChange}
                          type='text'
                          required
                        />
                      </label>
                      <label>
                        Date
                        <input
                          disabled={isViewOnly}
                          placeholder='mm-dd-yyyy'
                          value={formData.sessionDate}
                          name='sessionDate'
                          onChange={onChange}
                          type='text'
                          pattern='[0-9]{2}-[0-9]{2}-[0-9]{4}'
                          required
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div>
                      <label>
                        Session Number
                        <input
                          disabled={isViewOnly}
                          placeholder='Session Number'
                          value={formData.sessionNumber}
                          name='sessionNumber'
                          onChange={onChange}
                          type='text'
                          pattern='[0-9]*'
                          required
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <label>
                      How did client feel last treatment:
                      <textarea
                        value={formData.clientFeelingLastTreatment}
                        rows={5}
                        onChange={onChange}
                        name='clientFeelingLastTreatment'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>

                  <div>
                    <label>
                      How does client feel today:
                      <textarea
                        value={formData.clientFeelingToday}
                        rows={5}
                        onChange={onChange}
                        name='clientFeelingToday'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>

                  <div>
                    <label>
                      Observation of client:
                      <textarea
                        value={formData.clientObservation}
                        rows={5}
                        onChange={onChange}
                        name='clientObservation'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>
                </div>

                <div>
                  <div>Foot Observation</div>

                  <div>
                    <label>
                      Left foot
                      <textarea
                        disabled={isViewOnly}
                        placeholder='Left foot'
                        value={formData.leftFoot}
                        name='leftFoot'
                        onChange={onChange}
                        type='text'
                        required
                      />
                    </label>
                    <label>
                      Right foot
                      <textarea
                        disabled={isViewOnly}
                        placeholder='Right foot'
                        value={formData.rightFoot}
                        name='rightFoot'
                        onChange={onChange}
                        type='text'
                        pattern='[0-9]{2}-[0-9]{2}-[0-9]{4}'
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label>
                      Findings during treatment:
                      <textarea
                        value={formData.treatmentFindings}
                        rows={5}
                        onChange={onChange}
                        name='treatmentFindings'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>

                  <div>
                    <label>
                      Reflexes that were worked on:
                      <textarea
                        value={formData.workedReflexes}
                        rows={5}
                        onChange={onChange}
                        name='workedReflexes'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>

                  <div>
                    <label>
                      Results:
                      <textarea
                        value={formData.results}
                        rows={5}
                        onChange={onChange}
                        name='results'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>

                  <div>
                    <label>
                      Clients comments:
                      <textarea
                        value={formData.clientComments}
                        rows={5}
                        onChange={onChange}
                        name='clientComments'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>
                </div>

                <div>
                  <div>
                    <label>
                      Observation end of treatment:
                      <textarea
                        value={formData.treatmentObservation}
                        rows={5}
                        onChange={onChange}
                        name='treatmentObservation'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>
                </div>

                <div>
                  <div>
                    <label>
                      Plan for next treatment:
                      <textarea
                        value={formData.nextTreatmentPlan}
                        rows={5}
                        onChange={onChange}
                        name='nextTreatmentPlan'
                        required
                        disabled={isViewOnly}
                      />
                    </label>
                  </div>
                </div>

                <div>
                  <div
                    onClick={() =>
                      !isViewOnly && setCurrentColor('rgb(249 115 22)')
                    }
                  >
                    <div />
                    Tenderness
                  </div>
                  <div
                    onClick={() =>
                      !isViewOnly && setCurrentColor('rgb(14 165 233)')
                    }
                  >
                    <div />
                    Blister
                  </div>
                  <div
                    onClick={() =>
                      !isViewOnly && setCurrentColor('rgb(245 158 11)')
                    }
                  >
                    <div />
                    Calluses
                  </div>
                  <div
                    onClick={() =>
                      !isViewOnly && setCurrentColor('rgb(16 185 129)')
                    }
                  >
                    <div />
                    Disturbance
                  </div>
                  <div
                    onClick={() =>
                      !isViewOnly && setCurrentColor('rgb(139 92 246)')
                    }
                  >
                    <div />
                    Puffiness
                  </div>
                  <div
                    onClick={() =>
                      !isViewOnly && setCurrentColor('rgb(239 68 68)')
                    }
                  >
                    <div />
                    Inflammation
                  </div>
                  <div
                    onClick={() =>
                      !isViewOnly && setCurrentColor('rgb(115 115 115)')
                    }
                  >
                    <div />
                    Scars
                  </div>
                </div>

                <div>
                  {formData.footChart.map((area, index) => (
                    <div key={index} style={area} />
                  ))}
                  <img
                    src={chart}
                    alt='chart'
                    ref={chartRef}
                    onClick={handleOnClick}
                  />
                </div>

                {!isViewOnly && (
                  <div>
                    <div>
                      <Button
                        type='submit'
                        text='Submit'
                        isDisabled={
                          !(
                            formData.name &&
                            formData.sessionNumber &&
                            formData.sessionDate
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default TreatmentForm;
