import { Link } from 'react-router-dom';

import Banner from '../Banner';
import Button from '../Button';
import massageRoom from '../../assets//imgs/bgs/massage-room.jpg';
import deepTissue from '../../assets//imgs/bgs/deep-tissue.jpg';
import swedish from '../../assets//imgs/bgs/swedish.jpg';
import footReflexology from '../../assets//imgs/bgs/foot-reflexology.jpg';
import hotStones from '../../assets//imgs/bgs/hotstones.jpg';
import triggerPoints from '../../assets//imgs/bgs/trigger-points.jpg';

import styles from './services.module.css';

const AboutUs = () => {
  return (
    <div className={styles.container}>
      <Banner title='Services' src={massageRoom} isSmall hasOverlay />
      <Banner
        hasOverlay
        title='Deep Tissue Massage'
        description={
          <>
            <p>
              Deep Tissue Massage is a deeper more intense massage. This
              technique uses slow, deep guided strokes and firm pressure
              designed to relieve severe tension and reach below the superficial
              muscles. Deep Tissue Massage is often recommended for individuals
              who experience consistent pain and soreness in both large and
              small muscle groups.
            </p>
            <Link to='/booking'>
              <Button text='Book Now' />
            </Link>
          </>
        }
        src={deepTissue}
        isSmall
      />

      <Banner
        hasOverlay
        title='Swedish Massage'
        description={
          <>
            <p>
              Swedish Massage is the most common and best known type of massage.
              It involves long, fluid strokes of muscles and tissues with
              pressure that varies from light to medium to firm. This type of
              massage is considered to be one of the most relaxing styles. Your
              Massage Therapist will adjust the pressure according to your
              sensitivity and preference.
            </p>
            <Link to='/booking'>
              <Button text='Book Now' />
            </Link>
          </>
        }
        src={triggerPoints}
        isSmall
      />

      <Banner
        hasOverlay
        title='Foot Reflexology'
        description={
          <>
            <p>
              Foot Reflexology is a type of healing art that involves applying
              different amounts of pressure to the feet. It’s based on a theory
              that these body parts are connected to certain organs and body
              systems. Applying pressure to these parts offers a range of health
              benefits.
            </p>
            <Link to='/booking'>
              <Button text='Book Now' />
            </Link>
          </>
        }
        src={footReflexology}
        isSmall
      />

      <Banner
        hasOverlay
        title='Hot Stones Massage'
        description={
          <>
            <p>
              Hot Stone Massage is a form of massage therapy that follows the
              same principles of Swedish Massage with the addition of heated
              stones, which helps lead to deep relaxation. Adding heat to
              specific areas on the body enhances the feelings of relaxation and
              peace. The penetrating effects of the heated stones allows the
              massage to be delivered without excessive pressure.
            </p>
            <Link to='/booking'>
              <Button text='Book Now' />
            </Link>
          </>
        }
        src={hotStones}
        isSmall
      />

      <Banner
        hasOverlay
        title='Relaxation Massage'
        description={
          <>
            <p>
              A relaxation massage is a gentle, soothing massage technique aimed
              at promoting relaxation, reducing stress, and easing muscle
              tension. It typically involves long, smooth strokes, kneading, and
              gentle manipulation of the muscles. This type of massage is
              usually performed in a tranquil environment with soft lighting,
              calming music, and aromatic scents to enhance relaxation. It's
              great for unwinding and rejuvenating both the body and mind.
            </p>
            <Link to='/booking'>
              <Button text='Book Now' />
            </Link>
          </>
        }
        src={swedish}
        isSmall
      />
    </div>
  );
};

export default AboutUs;
