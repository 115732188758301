import { Routes, Route } from 'react-router-dom';

import Home from '../Home';
import Booking from '../Booking';
import TimeLine from '../TimeLine';
import AboutMe from '../AboutMe';
import Services from '../Services';
import MyGallery from '../MyGallery';
import ConsentForm from '../ConsentForm';
import HealthHistoryForm from '../HealthHistoryForm';
import TreatmentForm from '../TreatmentForm';
import GiftCard from '../GiftCard';
import Forms from '../Forms';
import FormDetails from '../FormDetails';
import Reviews from '../Reviews';
import ReviewsVertical from '../ReviewsVertical';
import { useState } from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const Layout = () => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  return (
    <div>
      <ScrollToTop />
      <NavBar isNavBarOpen={isNavBarOpen} setIsNavBarOpen={setIsNavBarOpen} />
      <div>
        <Routes>
          <Route path='/reviews' element={<Reviews />} />
          <Route path='/reviews-vertical' element={<ReviewsVertical />} />
          <Route path='/booking' element={<Booking />} />
          <Route path='/timeline' element={<TimeLine />} />
          <Route path='/about-us' exact element={<AboutMe />} />
          <Route path='/services' exact element={<Services />} />
          <Route path='/gallery' exact element={<MyGallery />} />
          <Route path='/gift-card' exact element={<GiftCard />} />
          <Route path='/gift-card/:code' exact element={<Booking />} />
          <Route path='/all-forms' exact element={<Forms />} />
          <Route path='/form/details/:code' exact element={<FormDetails />} />
          <Route path='/forms/consent' exact element={<ConsentForm />} />
          <Route path='/forms/consent/:code' exact element={<ConsentForm />} />
          <Route
            path='/forms/health-history/:code'
            exact
            element={<HealthHistoryForm />}
          />
          <Route
            path='/forms/treatment/:code'
            exact
            element={<TreatmentForm />}
          />
          <Route
            path='/forms/treatment/:code/:treatmentId'
            exact
            element={<TreatmentForm />}
          />
          <Route path='/' exact element={<Home />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
