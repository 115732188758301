import Heading from '../Heading';
import Card from '../Card';

import Button from '../Button';

import { ReactComponent as Light } from '../../assets/imgs/light.svg';
import { ReactComponent as Medium } from '../../assets/imgs/medium.svg';
import { ReactComponent as Firm } from '../../assets/imgs/firm.svg';
import { ReactComponent as Deep } from '../../assets/imgs/deep.svg';

import styles from './booking.module.css';

const Step6 = ({ bookingDetails, setBookingDetails, next, prev, GiftCard }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        next(8);
      }}
    >
      <Heading title='What pressure sounds right?' />
      {GiftCard}
      <div className={styles.grid2}>
        <Card
          pressure
          Icon={Light}
          title='Light'
          description='Mostly long, sweeping, relaxing, gentle strokes; chiefly affects superficial tissue layers'
          isSelected={bookingDetails.pressure === 'light'}
          onClick={() =>
            setBookingDetails({
              ...bookingDetails,
              pressure: 'light'
            })
          }
        />
        <Card
          pressure
          Icon={Medium}
          title='Medium'
          description='Incorporates techniques such as kneading & friction to target more specific muscles'
          isSelected={bookingDetails.pressure === 'medium'}
          onClick={() =>
            setBookingDetails({
              ...bookingDetails,
              pressure: 'medium'
            })
          }
        />
        <Card
          pressure
          Icon={Firm}
          title='Firm'
          description='This intense pressure targets deeper muscle layers, incorporating more therapeutic techniques'
          isSelected={bookingDetails.pressure === 'firm'}
          onClick={() =>
            setBookingDetails({
              ...bookingDetails,
              pressure: 'firm'
            })
          }
        />
        <Card
          pressure
          Icon={Deep}
          title='Deep'
          description='The most heavy-handed of the pressures, involves skilled techniques affecting entire muscle groups & can be painful'
          isSelected={bookingDetails.pressure === 'deep'}
          onClick={() =>
            setBookingDetails({
              ...bookingDetails,
              pressure: 'deep'
            })
          }
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button type='button' text='Back' small action={() => prev(6)} />
        <Button
          type='submit'
          text='Continue'
          isDisabled={!bookingDetails.pressure}
        />
      </div>
    </form>
  );
};

export default Step6;
