import { useEffect, useRef } from 'react';

import { ReactComponent as MaleSVG } from '../../assets/imgs/male.svg';
import { ReactComponent as FemaleSVG } from '../../assets/imgs/female.svg';

const Body = ({ areas = [], gender }) => {
  const bodyRef = useRef(null);

  useEffect(() => {
    areas.forEach((area) => {
      Array.from(
        bodyRef.current.querySelectorAll(`[data-type="${area}"]`)
      ).forEach((element) =>
        element.style.setProperty('fill', 'rgb(var(--foreground))')
      );
    });
  }, [areas]);

  return (
    gender &&
    (gender === 'male' ? (
      <MaleSVG alt='' ref={bodyRef} height='500' />
    ) : (
      <FemaleSVG alt='' ref={bodyRef} height='500' />
    ))
  );
};

export default Body;
