import massage from '../../assets/imgs/bgs/massage-3.jpg';
import profile from '../../assets/imgs/profile.jpg';
import Banner from '../Banner';
import styles from './aboutMe.module.css';

const AboutMe = () => {
  return (
    <div>
      <Banner src={massage} title='About Us' isSmall hasOverlay />
      <div className={styles.container}>
        {/* <div
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(var(--background), 0.5) , rgba(var(--background), 0.5)),url(${profile})`
          }}
          className={styles.profile}
        /> */}
        Welcome to GTA Home Spa, where wellness meets tranquility.
        <br />
        <br />
        My name is Mina, with certifications in foot reflexology and over 5
        years of experience in massage therapy and bodywork, I've honed my
        skills across diverse cultures, from the vibrant streets of Hong Kong to
        the serene landscapes of Canada.
        <br />
        <br />
        Whether you seek relief from the stresses of daily life or simply wish
        to indulge in self-care, I offer a range of specialized services
        tailored to your needs. From the therapeutic depths of deep tissue
        massage to the soothing rhythms of Swedish massage, from the
        rejuvenating warmth of hot stones to the ancient art of foot
        reflexology, each session is crafted to restore balance and harmony to
        your body and soul.
        <br />
        <br />
        Convenience is key, and I understand the demands of modern life. That's
        why I offer mobile visits, bringing the spa experience directly to you,
        whether it's for individual treatments, group gatherings, or special
        occasions. Alternatively, you're welcome to unwind in the tranquil
        ambiance of my residence.
        <br />
        <br />
        At GTA Home Spa, my ultimate mission is to fulfill your wellness
        aspirations, transcending the ordinary to create an extraordinary
        journey of healing and rejuvenation. Together, let's embark on a path
        towards holistic well-being, where mind, body, and soul unite in perfect
        harmony.
      </div>
    </div>
  );
};

export default AboutMe;
