import Heading from '../Heading';

import Button from '../Button';

import styles from './booking.module.css';

const PHONE_REGEX = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const getMaskedPhone = (phone) => {
  const phoneDigits = phone.match(/(\d{3}).*?(\d{3}).*?(\d{4})/) || [];
  const maskedPhone =
    '(' + phoneDigits[1] + ') ' + phoneDigits[2] + '-' + phoneDigits[3];

  return phone && maskedPhone;
};

const Step8 = ({ bookingDetails, setBookingDetails, next, prev, GiftCard }) => {
  const { firstName, lastName, email, phone, clientNotes } =
    bookingDetails || {};

  const handleFirstName = (e) => {
    setBookingDetails({
      ...bookingDetails,
      firstName: e.target.value
    });
  };
  const handleLastName = (e) => {
    setBookingDetails({
      ...bookingDetails,
      lastName: e.target.value
    });
  };
  const handlePhone = (e) => {
    let maskedPhone = e.target.value;

    if (PHONE_REGEX.test(e.target.value)) {
      maskedPhone = getMaskedPhone(e.target.value);
    }

    setBookingDetails({
      ...bookingDetails,
      phone: maskedPhone
    });
  };
  const handleEmail = (e) => {
    setBookingDetails({
      ...bookingDetails,
      email: e.target.value
    });
  };
  const handleNotes = (e) => {
    setBookingDetails({
      ...bookingDetails,
      clientNotes: e.target.value
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        next(9);
      }}
    >
      <Heading title='Your Information' />
      {GiftCard}
      <div className={styles.container}>
        <div className={styles.field}>
          <div className={styles.fieldGroup}>
            <div className={`${styles.fieldGroup} ${styles.responsiveColumn}`}>
              <label className={styles.field}>
                First Name
                <input
                  placeholder='James'
                  value={firstName}
                  onChange={handleFirstName}
                  required
                />
              </label>

              <label className={styles.field}>
                Last Name
                <input
                  placeholder='Smith'
                  value={lastName}
                  onChange={handleLastName}
                  required
                />
              </label>
            </div>
            <div className={`${styles.fieldGroup} ${styles.responsiveColumn}`}>
              <label className={styles.field}>
                Phone
                <input
                  placeholder='(123) 456-7890'
                  value={phone}
                  onChange={handlePhone}
                  type='tel'
                  pattern='[\(]\d{3}[\)][\s]\d{3}[\-]\d{4}'
                  required
                />
              </label>
              <label className={styles.field}>
                Email
                <input
                  placeholder='you@example.com'
                  value={email}
                  onChange={handleEmail}
                  type='email'
                  required
                />
              </label>
            </div>
            <label className={styles.field}>
              Notes:
              <textarea value={clientNotes} rows={5} onChange={handleNotes} />
            </label>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          type='button'
          text='Back'
          small
          action={() => {
            if (bookingDetails.treatmentType === 'foot reflexology') {
              prev(4);
            } else {
              prev(7);
            }
          }}
        />
        <Button
          type='submit'
          text='Continue'
          isDisabled={
            !(
              bookingDetails.firstName &&
              bookingDetails.lastName &&
              bookingDetails.phone &&
              bookingDetails.email
            )
          }
        />
      </div>
    </form>
  );
};

export default Step8;
