import { ReactComponent as Customs } from '../../assets/imgs/customs.svg';
import { ReactComponent as Method } from '../../assets/imgs/method.svg';
import { ReactComponent as Product } from '../../assets/imgs/product.svg';
import bg3 from '../../assets/imgs/bgs/tools-2.jpeg';
import bg1 from '../../assets/imgs/bgs/massage-room.jpeg';
import Banner from '../Banner';
import styles from './home.module.css';

const Home = () => {
  return (
    <div className={styles.homeContainer}>
      <Banner
        title='GTA HOME SPA'
        description='Your Journey to Total Serenity Starts Here'
        src={bg1}
        hasOverlay
      />

      <div className={styles.splitLayout}>
        <div className={styles.left}>
          <div className={`${styles.heading} padding`}>
            Find peace and let go of stress
          </div>

          <div>
            Our carefully crafted massages aim to balance your body, mind, and
            emotions. Whether you need relief from tension, pain, or just want a
            relaxing escape, our sanctuary is ideal for you.
          </div>

          <div className={styles.cardsContainer}>
            <div className={styles.card}>
              <div>
                <Customs className={styles.icon} />
              </div>
              <div>
                <div className={styles.subHeading}>Tailored Massage</div>
                <div>
                  Each massage is unique. I customize every session to fit your
                  preferences, providing a personalized experience that
                  addresses your individual needs. And you can enjoy this
                  tailored session in the comfort of your home.
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div>
                <Method className={styles.icon} />
              </div>
              <div>
                <div className={styles.subHeading}>Catalytic Process</div>
                <div>
                  Regardless of your pressure preferences, I utilize an
                  energetic, catalyzing technique. In simpler terms, I awaken
                  your muscles.
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div>
                <Product className={styles.icon} />
              </div>
              <div>
                <div className={styles.subHeading}>Quality Oils</div>
                <div>
                  I utilize premium massage oils meticulously crafted with a
                  blend of lavender and almond extracts, renowned for their
                  myriad benefits to the body. Lavender oil, prized for its
                  calming attributes, aids in stress reduction and induces
                  relaxation during massages. Meanwhile, almond oil offers
                  exceptional skin hydration and nourishment. Together, this
                  blend provides a professional-grade, soothing experience that
                  promotes both physical and mental well-being.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.right}></div> */}
      </div>

      <Banner
        title='The Art Of Self-Care'
        description='Your journey to rejuvenation begins here. Book your appointment
        today and rediscover the harmony of body, mind, and spirit.'
        src={bg3}
        hasOverlay
      />
    </div>
  );
};

export default Home;
