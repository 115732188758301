import { useEffect, useState } from 'react';
import qs from 'query-string';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';

import TimelineSlots from '../TimelineSlots';
import Time from '../Time';
import StopWatch from '../StopWatch';
import Body from '../Booking/Body';

import settings from '../../config';
import { ReactComponent as Logo } from '../../assets/imgs/logo.svg';
import { ReactComponent as Light } from '../../assets/imgs/light.svg';
import { ReactComponent as Medium } from '../../assets/imgs/medium.svg';
import { ReactComponent as Firm } from '../../assets/imgs/firm.svg';
import { ReactComponent as Deep } from '../../assets/imgs/deep.svg';

import bg1 from '../../assets/imgs/bgs/massage-room.jpeg';

import styles from './session.module.css';
import Banner from '../Banner';

const pressure = {
  light: (
    <div className={styles.pressure}>
      <Light alt='Light' title='Light' height={50} /> Light
    </div>
  ),
  medium: (
    <div className={styles.pressure}>
      <Medium alt='Medium' title='Medium' height={50} /> Medium
    </div>
  ),
  firm: (
    <div className={styles.pressure}>
      <Firm alt='Firm' title='Firm' height={50} /> Firm
    </div>
  ),
  deep: (
    <div className={styles.pressure}>
      <Deep alt='Deep' title='Deep' height={50} /> Deep
    </div>
  )
};

const Session = () => {
  const [start, setStart] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [left, setLeft] = useState(-360);
  const [stopWatch, setStopWatch] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const getBookings = async () => {
      const response = await axios.get(`${settings.api}bookings`);

      if (response.data.statusCode === 200) {
        setBookings(response.data.data);
      }
    };
    getBookings();
  }, []);

  const { firstName = '', lastName = '' } = selectedSlot || {};

  const {
    customerName = `${firstName} ${lastName}`,
    ytSrc = 'tNkZsRW7h2c',
    date = ''
  } = qs.parse(window.location.search);

  const reset = () => {
    setStopWatch(false);
  };

  const handleSessionStarted = () => {
    setStopWatch(true);
  };

  const handleOnClick = () => {
    reset();

    if (firstName && ytSrc) {
      handleSessionStarted();
    }
  };

  const handlePlay = () => {
    setStart(!start);
  };

  const toggleTimeline = () => {
    setLeft(left ? 0 : -360);
  };

  const seconds =
    (new Date(selectedSlot.bookingEndTime) -
      new Date(selectedSlot.bookingStartTime)) /
    1000;

  const currentDate = date
    ? new Date(date).toISOString().replace('T', ' ').slice(0, 19)
    : new Date();

  return (
    <div className={styles.container}>
      {start && (
        <div className={styles.iframe}>
          <iframe
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/${ytSrc}?&autoplay=1&controls=0`}
            title='YouTube video player'
            allow='autoplay;'
          ></iframe>
        </div>
      )}
      <div
        style={
          (start && { background: 'rgba(0, 0, 0, 0.5)', color: 'white' }) || {}
        }
      >
        <div>
          <Banner
            title={`Welcome, ${customerName}`}
            description={
              <>
                <div onClick={handleOnClick}>
                  {stopWatch && (
                    <StopWatch
                      seconds={seconds}
                      setCurrentTime={setCurrentTime}
                    />
                  )}
                  {pressure[selectedSlot?.pressure]}
                  <Body
                    gender={selectedSlot.gender}
                    areas={selectedSlot?.body}
                    key={selectedSlot.id}
                  />
                  <div className={styles.conditions}>
                    {(selectedSlot.healthConditions?.length &&
                      selectedSlot.healthConditions.map((healthCondition) => (
                        <div>{healthCondition}</div>
                      ))) ||
                      null}
                  </div>
                  <div className={styles.conditions}>
                    {(selectedSlot.helpSelection?.length &&
                      selectedSlot.helpSelection.map((selection) => (
                        <div>{selection}</div>
                      ))) ||
                      null}
                  </div>
                  <div className={styles.conditions}>
                    {(selectedSlot.lifeStyle?.length &&
                      selectedSlot.lifeStyle.map((life) => (
                        <div>{life}</div>
                      ))) ||
                      null}
                  </div>
                </div>
              </>
            }
            src={bg1}
            hasOverlay
          />
          <div className={styles.progress}>
            <CircularProgressbar
              value={((seconds - currentTime) * 100) / seconds}
              styles={{
                path: {
                  stroke: 'rgb(var(--foreground))'
                },
                trail: {
                  stroke: 'rgb(var(--background))'
                }
              }}
            />
            <Logo
              alt=''
              height={40}
              style={{
                fill: 'rgb(var(--foreground))'
              }}
              className={styles.logo}
            />
          </div>
        </div>
        {
          <div className={styles.bottomRight} onClick={handlePlay}>
            &#9654;
          </div>
        }
        <div className={styles.clock}>
          <Time />
        </div>
      </div>
      <div
        className={styles.bottomLeft}
        style={{ left: left + 360 + 50 }}
        onClick={() => {
          toggleTimeline();
        }}
      >
        {(seconds && Math.ceil((seconds - currentTime) / 60)) || 0}
      </div>
      <div className={styles.timeline} style={{ left }}>
        <TimelineSlots
          busySlots={bookings.filter((slot) => {
            return (
              new Date(slot.bookingTime).toISOString().slice(0, 10) ===
              new Date(currentDate).toISOString().slice(0, 10)
            );
          })}
          date={currentDate}
          setSelectedSlot={(slot) => {
            reset();
            return setSelectedSlot(slot);
          }}
        />
      </div>
    </div>
  );
};

export default Session;
