import { getTimeFromDate } from '../../utils';
import RadioBox from '../RadioBox';

import styles from '../Booking/booking.module.css';

const getAvailability = (busySlots, booking) => {
  const todaySlots = [];
  const dayStartTime = 9;
  const dayEndTime = 21;
  const interval = 30;

  for (
    let i = 0, j = 0;
    i < (dayEndTime - dayStartTime) * (60 / interval) * interval;
    i += interval, j++
  ) {
    const bookingStartTime = new Date(
      new Date(booking.bookingTime).setHours(dayStartTime, i, 0, 0)
    );
    const bookingEndTime = new Date(
      new Date(booking.bookingTime).setHours(
        dayStartTime,
        i + booking.slot,
        0,
        0
      )
    );

    if (
      new Date(bookingEndTime).getHours() >= dayEndTime &&
      new Date(bookingEndTime).getMinutes() >= interval
    ) {
      break;
    }

    const overlappedSlots = busySlots.find((busySlot) => {
      const busySlotStartTime = new Date(
        busySlot.bookingStartTime.replace(' ', 'T') + 'Z'
      ).getTime();
      const busySlotEndTime = new Date(
        busySlot.bookingEndTime.replace(' ', 'T') + 'Z'
      ).getTime();

      const currentSlotStartTime = new Date(bookingStartTime).getTime();
      const currentSlotEndTime = new Date(bookingEndTime).getTime();

      if (
        busySlotStartTime < currentSlotEndTime &&
        busySlotEndTime > currentSlotStartTime
      ) {
        return true;
      } else {
        return false;
      }
    });

    todaySlots.push({
      isBusy: overlappedSlots ? true : false,
      bookingStartTime,
      bookingEndTime
    });
  }

  return todaySlots.filter(
    (slot) => slot.isBusy === false && slot.bookingStartTime > new Date()
  );
};

const Slots = ({
  bookingsLoaded,
  bookingDetails,
  setBookingDetails,
  busySlots = []
}) => {
  const filteredSlots = getAvailability(busySlots, bookingDetails);

  if (!bookingsLoaded) {
    return <div className={styles.margin}>Finding available time slots...</div>;
  }

  return (
    <div>
      <div className={styles.margin}>Time</div>
      {filteredSlots.map((slot) => {
        const bookingStartTime = new Date(slot.bookingStartTime);

        return (
          <div
            className={`${styles.reverseGridItem} ${styles.zebra} ${
              (bookingStartTime.getTime() ===
                bookingDetails.bookingStartTime?.getTime() &&
                styles.selected) ||
              ''
            }`}
            key={slot.bookingStartTime}
            onClick={() => {
              setBookingDetails({
                ...bookingDetails,
                ...slot
              });
            }}
          >
            <RadioBox
              isSelected={
                bookingStartTime.getTime() ===
                bookingDetails.bookingStartTime?.getTime()
              }
            />
            {`${getTimeFromDate(new Date(bookingStartTime))}`}
          </div>
        );
      })}
      {!filteredSlots.length && (
        <div className={styles.padding}>No time available</div>
      )}
    </div>
  );
};

export default Slots;
