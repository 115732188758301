import { useEffect, useRef } from 'react';

import Heading from '../Heading';

import Button from '../Button';

import styles from './booking.module.css';

import { ReactComponent as Female } from '../../assets/imgs/female.svg';
import { ReactComponent as Male } from '../../assets/imgs/male.svg';

const Step5 = ({ bookingDetails, setBookingDetails, next, prev, GiftCard }) => {
  const bodyRef = useRef(null);

  const toggleColor = (e) => {
    const area = e.target.getAttribute('data-type');

    if (area !== 'no' && area !== '') {
      if (bookingDetails.body.find((part) => part === area)) {
        Array.from(
          bodyRef.current.querySelectorAll(`[data-type="${area}"]`)
        ).forEach((element) => element.style.setProperty('fill', ''));

        setBookingDetails({
          ...bookingDetails,
          body: bookingDetails.body.filter((b) => b !== area)
        });
      } else {
        Array.from(
          bodyRef.current.querySelectorAll(`[data-type="${area}"]`)
        ).forEach((element) =>
          element.style.setProperty('fill', 'rgb(var(--foreground))')
        );

        setBookingDetails({
          ...bookingDetails,
          body: [...bookingDetails.body, area]
        });
      }
    }
  };

  useEffect(() => {
    bookingDetails.body.forEach((area) => {
      Array.from(
        bodyRef.current.querySelectorAll(`[data-type="${area}"]`)
      ).forEach((element) =>
        element.style.setProperty('fill', 'rgb(var(--foreground))')
      );
    });
  }, [bookingDetails.body]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        next(7);
      }}
    >
      <Heading title='Want to focus on anything?' />
      {GiftCard}
      <div className={styles.figure}>
        {(bookingDetails.gender === 'male' && (
          <Male height='500' alt='' ref={bodyRef} onClick={toggleColor} />
        )) || (
          <Female height='500' alt='' ref={bodyRef} onClick={toggleColor} />
        )}

        <div className={styles.buttonContainer}>
          <Button type='button' text='Back' small action={() => prev(5)} />
          <Button type='submit' text='Continue' />
        </div>
      </div>
    </form>
  );
};

export default Step5;
