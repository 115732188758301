import Heading from '../Heading';
import Card from '../Card';
import Button from '../Button';

import styles from './booking.module.css';

const Step1 = ({ bookingDetails, setBookingDetails, next, GiftCard }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        next(2);
      }}
    >
      <Heading title="Let's talk time!" />
      {GiftCard}

      <div className={styles.field}>
        <div>
          <div>Massage</div>
          <div className={styles.grid2}>
            <Card
              title='60 min - $100'
              description='Personalized, full body focus or specific service for tension areas.'
              isSelected={
                bookingDetails.slot === 60 &&
                bookingDetails.treatmentType === 'massage' &&
                bookingDetails.discountCode === ''
              }
              onClick={() =>
                setBookingDetails({
                  ...bookingDetails,
                  slot: 60,
                  treatmentType: 'massage',
                  discountCode: '',
                  price: 100
                })
              }
            />
            <Card
              title='90 min - $140'
              description='Allows for further focus on specific areas & wider array of techniques'
              isSelected={
                bookingDetails.slot === 90 &&
                bookingDetails.treatmentType === 'massage'
              }
              onClick={() =>
                setBookingDetails({
                  ...bookingDetails,
                  slot: 90,
                  treatmentType: 'massage',
                  discountCode: '',
                  price: 140
                })
              }
            />
            <Card
              title='120 min - $180'
              description='The ultimate experience'
              isSelected={
                bookingDetails.slot === 120 &&
                bookingDetails.treatmentType === 'massage'
              }
              onClick={() =>
                setBookingDetails({
                  ...bookingDetails,
                  slot: 120,
                  treatmentType: 'massage',
                  discountCode: '',
                  price: 180
                })
              }
            />
          </div>
        </div>

        <div>
          <div>Foot Reflexology</div>
          <div className={styles.grid2}>
            <Card
              title='60 min - $80'
              description='Foot Reflexology'
              isSelected={
                bookingDetails.slot === 60 &&
                bookingDetails.treatmentType === 'foot reflexology'
              }
              onClick={() =>
                setBookingDetails({
                  ...bookingDetails,
                  slot: 60,
                  treatmentType: 'foot reflexology',
                  discountCode: '',
                  price: 80
                })
              }
            />
            <Card
              title='90 min - $120'
              description='The ultimate experience for Foot Reflexology'
              isSelected={
                bookingDetails.slot === 90 &&
                bookingDetails.treatmentType === 'foot reflexology'
              }
              onClick={() =>
                setBookingDetails({
                  ...bookingDetails,
                  slot: 90,
                  treatmentType: 'foot reflexology',
                  discountCode: '',
                  price: 120
                })
              }
            />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type='submit'
            text='Continue'
            isDisabled={!bookingDetails.slot}
          />
        </div>
      </div>
    </form>
  );
};

export default Step1;
